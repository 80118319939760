@font-face {
  font-family: "Lato Regular";
  src: url("../fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Bold";
  src: url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato SemiBold";
  src: url("../fonts/Lato/Lato-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Heavy";
  src: url("../fonts/Lato/Lato-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Medium";
  src: url("../fonts/Lato/Lato-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Black";
  src: url("../fonts/Lato/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?23lvil");
  src: url("../fonts/icomoon.eot?23lvil#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?23lvil") format("truetype"), url("../fonts/icomoon.woff?23lvil") format("woff"), url("../fonts/icomoon.svg?23lvil#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-iphone:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e910";
}

.icon-instagram:before {
  content: "\e911";
}

.icon-viber:before {
  content: "\e912";
}

.icon-whatsapp:before {
  content: "\e913";
}

.icon-telegram:before {
  content: "\e914";
}

.icon-pin:before {
  content: "\e915";
}

.icon-clock:before {
  content: "\e916";
}

.icon-tablet:before {
  content: "\e917";
}

.icon-mail:before {
  content: "\e918";
}

.icon-thumbtack:before {
  content: "\e919";
}

.icon-feather:before {
  content: "\e91a";
}

.icon-wave:before {
  content: "\e91b";
}

.icon-seat:before {
  content: "\e91c";
}

.icon-cancel:before {
  content: "\e91d";
}

.icon-safe:before {
  content: "\e91e";
}

.icon-down-arrow:before {
  content: "\e91f";
}

.icon-shopping-bag:before {
  content: "\e920";
}

.icon-up-arrow:before {
  content: "\e921";
}

/* top */
#top {
  display: block;
  min-height: auto;
}

#top .top-container {
  display: block;
  padding: 8px 0 0;
}

#top .top-container .not-mobile-container {
  padding-bottom: 8px;
  border-bottom: 1px solid #eeeeee;
}

#top #top-right-links > ul > li a {
  height: auto !important;
  line-height: 1 !important;
}

#top #top-right-links > ul > li {
  border-bottom: none;
}

#top #top-right-links > ul > li:hover {
  border-bottom: none;
}

.top-left-links,
.top-right-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-left-schedule-list,
.language-list,
.socials {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.top-left-schedule,
.top-left-email {
  margin-bottom: 4px;
}

.top-left-schedule-list li span {
  display: inline-block;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1;
  vertical-align: middle;
}

.top-left-schedule-list li span[class^="icon"] {
  line-height: 1;
  vertical-align: middle;
  color: #70a335;
  padding-right: 8px;
  font-size: 14px;
}

.top-left-email a:hover {
  color: #70a335;
}

.top-left-email a span {
  display: inline-block;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1;
  vertical-align: middle;
}

.top-left-email a span[class^="icon"] {
  line-height: 1;
  vertical-align: middle;
  padding-right: 8px;
  color: #70a335;
  font-size: 14px;
}

.ts-search-box {
  flex: 0 1 300px;
}

.cats-button {
  display: none;
}

#search {
  margin-bottom: 0;
  background: #eeeeee;
}

#search .input-lg {
  height: auto;
  line-height: 28px;
  border: none;
  border-radius: 0;
  background: #eeeeee;
  font-size: 12px;
}

#search .btn-lg {
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}

#search .btn-search {
  padding: 5px 10px;
}

#search .fa-search {
  color: #515356;
}

.call-me.custom-btn {
  padding: 4px 19px;
  margin-right: 16px;
}

.language-currency {
  margin-right: 18px;
}

.language-btn {
  border: none;
  background: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.language-btn:hover {
  color: #70a335;
}

.language-btn.active {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  color: #70a335;
}

.top-account > li > a {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.top-account > li > a i[class="fa fa-user"] {
  margin-bottom: 4px;
  color: #70a335;
  font-size: 15px;
}

.socials a {
  font-size: 14px;
}

/* header */
header {
  padding-top: 6px;
  padding-bottom: 14px;
}

#logo {
  margin: 0;
}

#logo img {
  width: 100%;
}

.ts-menu-box .ts-menu-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ts-menu-box .ts-menu-list li a {
  padding: 0 12px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.ts-menu-box .ts-menu-list li a:hover {
  color: #70a335;
}

.phones-top-box .phone-list {
  position: relative;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0 0 0 15px;
  margin: 0 0 3px;
}

.phones-top-box .phone-list:before {
  position: absolute;
  content: "\e900";
  top: 50%;
  left: -18px;
  transform: translateY(-50%);
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 23px;
  color: #70a335;
}

.phones-top-box .phone-list li {
  line-height: 1;
}

.phones-top-box .phone-list li a {
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.phones-top-box .phone-list li a:hover {
  color: #70a335;
}

#livesearch_search_results li {
  margin: 0 0 16px;
}

#livesearch_search_results li a {
  margin: 0 0 6px;
  font-family: 'Lato Regular' , Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px !important;
}

#livesearch_search_results li a:hover {
  font-family: 'Lato Regular' , Helvetica, Arial, sans-serif;
  text-decoration: none;
}

#livesearch_search_results li + span {
  color: #515356 !important;
}

#livesearch_search_results img {
  margin: 0 0 12px;
}

#menu .nav > li {
  border-top: none;
}

#menu .nav > li:hover {
  border-top: none;
}

#menu .nav > li > a {
  line-height: 1;
  padding: 15px 20px;
  text-transform: initial;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

#oct-bluring-box .menu-row {
  background: #515356;
  border: none;
}

#menu .dropdown-inner a {
  font-size: 14px;
}

#menu .see-all {
  color: #515356 !important;
}

.menu-row {
  margin-bottom: 0;
}

.menu-row.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  animation: smoothScroll .25s ease;
}

#menu .navbar-nav {
  width: 100%;
}

.navbar-nav > li.oct-mm-simplecat-last {
  float: right;
}

/* cart */
#cart {
  display: block;
  margin-bottom: 0;
  padding-top: 0;
  border-radius: 0;
}

#cart a {
  display: block;
  padding: 13px 18px 15px 30px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #ffffff;
}

#cart a #cart-total {
  position: relative;
  padding-left: 42px;
}

#cart a #cart-total i[class="icon-shopping-bag"] {
  position: absolute;
  top: -4px;
  left: -6px;
  color: #515356;
  font-size: 25px;
}

#cart a #cart-total .cart-total-price {
  position: absolute;
  top: 32%;
  left: 10px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  background: #ffffff;
  border-radius: 50%;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

footer {
  flex: 0 0 auto;
  padding: 22px 0 0;
  margin-top: 48px;
}

footer ul li {
  line-height: 20px;
  padding-left: 4px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

footer .text-about-company {
  line-height: 20px;
  padding: 0 116px 0 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

footer .subscribe-content .input-box {
  display: flex;
}

footer .subscribe-content .input-box input {
  padding: 13px 10px 14px 20px;
  border: none;
  border-radius: 0;
  background: #ffffff;
  color: #515356;
  font-size: 12px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
}

footer .third-row {
  padding: 50px 0 40px;
}

footer .third-row .h5 {
  margin: 0 0 20px;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-transform: initial;
}

footer .third-row ul li a {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

footer .third-row ul li a:hover {
  text-decoration: none;
}

footer .third-row .oct-messengers {
  display: flex;
  align-items: center;
}

footer .third-row .oct-messengers h5 {
  margin: 0 14px 0 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: initial;
}

footer .third-row .oct-messengers .oct-messengers-viber {
  margin-top: 2px;
  margin-right: 11px;
  font-size: 17px;
}

footer .footer-contacts ul li i,
footer .footer-contacts ul li span[class="icon-clock"] {
  font-size: 16px;
}

footer .footer-contacts ul li i[class="fa fa-envelope"] {
  font-size: 14px;
  margin-right: 4px;
}

footer .footer-contacts ul li span[class="icon-clock"] {
  font-size: 14px;
  margin-right: 4px;
}

footer .footer-bottom {
  padding: 8px 0;
  background: #cfcfcf;
  color: #515356;
}

footer .footer-bottom span {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

footer .footer-bottom .row {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

footer .footer-bottom .oct-copy {
  margin-top: 0;
}

footer .footer-bottom .oct-created-by {
  text-align: center;
}

footer .footer-bottom .oct-created-by a {
  color: #515356 !important;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

footer .footer-bottom .oct-created-by a:hover {
  color: #70a335;
}

footer .footer-bottom .payment-box span {
  opacity: 1;
}

/* slogan */
.common-home .slogan {
  margin: 0;
  padding: 0;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 0;
  text-transform: uppercase;
  text-align: center;
}

/* slideshow */
.common-home .oct-slideshow-box {
  padding: 0;
}

.common-home .oct-slideshow-box .owl-wrapper-outer {
  padding: 0;
}

.common-home .oct-slideshow-box .item {
  padding: 0;
}

.common-home h2 {
  text-align: center;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  text-transform: initial;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
}

/* slideshow plus */
#oct-slideshow0 {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

#oct-slideshow0 .owl-wrapper-outer {
  background: #e1e3e2;
  box-shadow: none;
}

#oct-slideshow0 .owl-controls {
  background: #ffffff;
  position: relative;
}

#oct-slideshow0 .owl-controls .container-r {
  position: relative;
  z-index: 4;
}

#oct-slideshow0 .owl-controls .container-r .owl-pagination {
  position: static;
  margin: -46px 0px 0 10px;
  text-align: left;
}

.oct-slideshow-plus {
  padding: 116px 0 112px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.oct-slideshow-plus > .container {
  position: relative;
  z-index: 2;
  background-image: none !important;
}

.oct-slideshow-plus .oct-slideshowplus-header {
  margin: 0 0 36px;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 40px;
  line-height: 1.2em;
}

.oct-slideshow-plus p {
  margin: 0 0 28px;
  font-size: 18px;
  line-height: 1.345em;
}

.oct-slideshow-box .owl-controls .owl-page span {
  background: #ffffff;
  border: none;
}

.oct-slideshow-box .owl-controls .owl-page.active span {
  border: none;
  background: #515356;
}

/* category wall */
.cat-wall {
  padding-top: 10px;
  padding-bottom: 20px;
  background: #ffffff;
}

.oct-category-item-box {
  height: auto;
  min-height: 246px;
  max-height: 246px;
  padding: 20px 15px 10px 0;
  box-shadow: none;
  border: 1px solid #a8adae;
  border-radius: 0;
}

.oct-category-item-box .oct-category-item-text {
  padding: 0;
}

.main-advantage-item .main-advantage-item-icon {
  padding: 0;
}

.oct-category-item-box .oct-category-item-text .oct-category-item-header {
  position: relative;
  padding-left: 12px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #515356 !important;
}

.oct-category-item-box .oct-category-item-text .oct-category-item-header:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  width: 4px;
  height: 100%;
  background: #515356;
}

/* main banner */
.main-banner {
  padding: 70px 0 40px;
  background: url(../../../../../image/catalog/new/5-banner.jpg) no-repeat center center;
  background-size: cover;
}

.main-banner .main-banner-context-title {
  margin: 0 0 30px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 26px;
}

.main-banner .main-banner-context-subtitle {
  line-height: 1.15;
  padding: 40px 30px;
  margin: 0 0 36px;
  background: #515356;
  color: #ffffff;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 32px;
}

.main-banner .main-banner-context-subtitle span {
  display: block;
}

.main-banner ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 40px;
}

.main-banner ul li {
  position: relative;
  display: block;
  padding: 0 0 0 26px;
  margin: 0 0 8px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.main-banner ul li:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #70a335;
}

.main-banner p {
  margin: 0 0 28px;
  font-size: 16px;
}

.main-banner .main-banner-text-bold {
  margin: 0 0 16px;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
}

.main-banner a {
  display: block;
}

.main-banner a img {
  width: 100%;
}

/* advantages */
.main-advantages {
  padding: 40px 0 60px;
}

.main-advantages .main-advantages-item {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.main-advantages .main-advantages-item .main-advantages-icon span {
  display: block;
  padding: 20px;
  margin-right: 30px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  color: #e76b18;
  font-size: 30px;
}

.main-advantages .main-advantages-item .main-advantages-text h4 {
  margin: 0 0 8px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}

.main-advantages .main-advantages-item .main-advantages-text p {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* featured em */
.oct-featured-em {
  padding: 0 0 40px;
  box-shadow: none;
}

.oct-featured-em-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #515356;
}

.oct-featured-em-header h2 {
  padding: 10px;
  margin: 0;
  background: #515356;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}

.oct-featured-em .owl-wrapper-outer {
  margin: 0;
  box-shadow: none;
}

.oct-featured-em .owl-carousel .owl-item .item {
  overflow-y: hidden;
  min-height: 512px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.oct-featured-em .owl-carousel .owl-item .image img {
  width: 100%;
}

.oct-featured-em .owl-carousel .owl-item .item:hover .oct-button {
  background: #70a335 !important;
  color: #ffffff !important;
}

.oct-featured-em .owl-carousel .owl-item .item .item-caption {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  bottom: -80px;
  z-index: 0;
  width: 100%;
  padding-top: 8px;
  background: #ffffff;
  transition: all .25s ease;
}

.oct-featured-em .owl-carousel .owl-item .item:hover .item-caption {
  bottom: 62px;
}

.oct-featured-em .owl-carousel .owl-item .item .item-caption .model {
  height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: #909090;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-featured-em .owl-carousel .owl-item .item .item-caption .name {
  height: 40px;
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
}

.oct-featured-em .owl-carousel .owl-item .item .item-caption .name a {
  margin: 0;
  font-size: 16px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-featured-em .owl-carousel .owl-item .name a:hover {
  color: #70a335;
  text-decoration: none;
}

.oct-featured-em .owl-carousel .owl-item .price {
  display: flex;
  flex-direction: column;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.oct-featured-em .owl-carousel .owl-item .oct-price-old {
  text-decoration: line-through;
}

.oct-featured-em .owl-carousel .owl-item .rating {
  position: static;
  margin: 0 0 0 auto;
}

.oct-featured-em .owl-carousel .owl-item .cart {
  position: relative;
  bottom: 0;
  z-index: 2;
}

.oct-featured-em .owl-carousel .owl-item .cart .oct-button {
  padding: 14px 20px 14px 20px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #eeeeee !important;
  color: #515356 !important;
  text-align: center;
}

.oct-featured-em .owl-carousel .owl-item .cart .oct-button:hover {
  background: #70a335 !important;
}

.oct-featured-em .owl-controls {
  margin-top: 30px;
}

/* oct latest */
.oct-latest {
  padding: 0 0 40px;
  box-shadow: none;
}

.oct-latest-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #515356;
}

.oct-latest-header h2 {
  padding: 10px;
  margin: 0;
  background: #515356;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}

.oct-latest .owl-wrapper-outer {
  margin: 0;
  box-shadow: none;
}

.oct-latest .owl-carousel .owl-item .item {
  overflow-y: hidden;
  min-height: 512px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.oct-latest .owl-carousel .owl-item .image img {
  width: 100%;
}

.oct-latest .owl-carousel .owl-item .item:hover .oct-button {
  background: #70a335 !important;
  color: #ffffff !important;
}

.oct-latest .owl-carousel .owl-item .item .item-caption {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  bottom: -80px;
  z-index: 0;
  width: 100%;
  padding-top: 8px;
  background: #ffffff;
  transition: all .25s ease;
}

.oct-latest .owl-carousel .owl-item .item:hover .item-caption {
  bottom: 62px;
}

.oct-latest .owl-carousel .owl-item .item .item-caption .model {
  height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: #909090;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-latest .owl-carousel .owl-item .item .item-caption .name {
  height: 40px;
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
}

.oct-latest .owl-carousel .owl-item .item .item-caption .name a {
  margin: 0;
  font-size: 16px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-latest .owl-carousel .owl-item .name a:hover {
  color: #70a335;
  text-decoration: none;
}

.oct-latest .owl-carousel .owl-item .price {
  display: flex;
  flex-direction: column;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.oct-latest .owl-carousel .owl-item .oct-price-old {
  text-decoration: line-through;
}

.oct-latest .owl-carousel .owl-item .rating {
  position: static;
  margin: 0 0 0 auto;
}

.oct-latest .owl-carousel .owl-item .cart {
  position: relative;
  bottom: 0;
  z-index: 2;
}

.oct-latest .owl-carousel .owl-item .cart .oct-button {
  padding: 14px 20px 14px 20px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #eeeeee !important;
  color: #515356 !important;
  text-align: center;
}

.oct-latest .owl-carousel .owl-item .cart .oct-button:hover {
  background: #70a335 !important;
}

.oct-latest .owl-controls {
  margin-top: 30px;
}

/* oct bestseller */
.oct-bestseller {
  padding: 0 0 40px;
  box-shadow: none;
}

.oct-bestseller-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #515356;
}

.oct-bestseller-header h2 {
  padding: 10px;
  margin: 0;
  background: #515356;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}

.oct-bestseller .owl-wrapper-outer {
  margin: 0;
  box-shadow: none;
}

.oct-bestseller .owl-carousel .owl-item .item {
  overflow-y: hidden;
  min-height: 512px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.oct-bestseller .owl-carousel .owl-item .image img {
  width: 100%;
}

.oct-bestseller .owl-carousel .owl-item .item:hover .oct-button {
  background: #70a335 !important;
  color: #ffffff !important;
}

.oct-bestseller .owl-carousel .owl-item .item .item-caption {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  bottom: -80px;
  z-index: 0;
  width: 100%;
  padding-top: 8px;
  background: #ffffff;
  transition: all .25s ease;
}

.oct-bestseller .owl-carousel .owl-item .item:hover .item-caption {
  bottom: 62px;
}

.oct-bestseller .owl-carousel .owl-item .item .item-caption .model {
  height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: #909090;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-bestseller .owl-carousel .owl-item .item .item-caption .name {
  height: 40px;
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
}

.oct-bestseller .owl-carousel .owl-item .item .item-caption .name a {
  margin: 0;
  font-size: 16px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-bestseller .owl-carousel .owl-item .name a:hover {
  color: #70a335;
  text-decoration: none;
}

.oct-bestseller .owl-carousel .owl-item .price {
  display: flex;
  flex-direction: column;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.oct-bestseller .owl-carousel .owl-item .oct-price-old {
  text-decoration: line-through;
}

.oct-bestseller .owl-carousel .owl-item .rating {
  position: static;
  margin: 0 0 0 auto;
}

.oct-bestseller .owl-carousel .owl-item .cart {
  position: relative;
  bottom: 0;
  z-index: 2;
}

.oct-bestseller .owl-carousel .owl-item .cart .oct-button {
  padding: 14px 20px 14px 20px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #eeeeee !important;
  color: #515356 !important;
  text-align: center;
}

.oct-bestseller .owl-carousel .owl-item .cart .oct-button:hover {
  background: #70a335 !important;
}

.oct-bestseller .owl-controls {
  margin-top: 30px;
}

/* oct product tab */
.oct-product-tab {
  padding: 0 0 40px;
  box-shadow: none;
}

.oct-product-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #515356;
}

.oct-product-tab-header h2 {
  padding: 10px;
  margin: 0;
  background: #515356;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}

.oct-product-tab ul.nav-tabs {
  display: flex;
  align-items: center;
  border: none;
  width: auto;
  margin: 0;
}

.oct-product-tab ul.nav-tabs > li {
  display: block;
  border-left: none;
}

.oct-product-tab ul.nav-tabs > li:hover,
.oct-product-tab ul.nav-tabs > li.active {
  background: transparent !important;
}

.oct-product-tab ul.nav-tabs > li a {
  padding: 10px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: initial;
  color: #eeeeee !important;
}

.oct-product-tab ul.nav-tabs > li a:hover,
.oct-product-tab ul.nav-tabs > li.active a {
  color: #515356 !important;
  font-size: 16px;
  text-transform: initial;
}

.oct-product-tab .owl-carousel .owl-item .item {
  overflow-y: hidden;
  min-height: 512px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.oct-product-tab .owl-carousel .owl-item .image img {
  width: 100%;
}

.oct-product-tab .owl-carousel .owl-item .item:hover .oct-button {
  background: #70a335 !important;
  color: #ffffff !important;
}

.oct-product-tab .owl-carousel .owl-item .item .item-caption {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  bottom: -80px;
  z-index: 0;
  width: 100%;
  padding-top: 8px;
  background: #ffffff;
  transition: all .25s ease;
}

.oct-product-tab .owl-carousel .owl-item .item:hover .item-caption {
  bottom: 62px;
}

.oct-product-tab .owl-carousel .owl-item .item .item-caption .model {
  height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: #909090;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-product-tab .owl-carousel .owl-item .item .item-caption .name {
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
}

.oct-product-tab .owl-carousel .owl-item .item .item-caption .name a {
  margin: 0;
  font-size: 16px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-product-tab .owl-carousel .owl-item .name a:hover {
  text-decoration: none;
}

.oct-product-tab .owl-carousel .owl-item .price {
  display: flex;
  flex-direction: column;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.oct-product-tab .owl-carousel .owl-item .oct-price-old {
  text-decoration: line-through;
}

.price-and-rating {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.oct-product-tab .owl-carousel .owl-item .rating {
  position: static;
  margin: 0 0 0 auto;
}

.oct-product-tab .owl-carousel .owl-item .cart {
  position: relative;
  bottom: 0;
  z-index: 2;
}

.oct-product-tab .owl-carousel .owl-item .cart .oct-button {
  padding: 14px 20px 14px 20px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #eeeeee !important;
  color: #515356 !important;
}

.oct-product-tab .owl-carousel .owl-item .cart .oct-button:hover {
  background: #70a335 !important;
}

.options-effect {
  min-height: 34px;
  margin: 0 0 14px;
}

.options-effect #kit-info-cat {
  display: none;
  margin: 0 0 12px;
}

.options-effect #kit-info-cat .kit-cat {
  display: flex;
  flex-flow: row wrap;
}

.options-effect #kit-info-cat .kjseries_cat-name {
  margin-right: 12px;
  color: #999ea5;
  font-size: 14px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.options-effect #kit-info-cat .kjseries-category-item {
  padding: 0 !important;
  margin-right: 10px;
  margin-bottom: 10px;
  outline: none;
  border: 2px solid #eeeeee;
  box-shadow: none;
}

.options-effect #kit-info-cat .kjseries-category-item img {
  width: 24px;
  height: 24px;
}

.options-effect #kit-info-cat .kjseries-category-item:last-child {
  margin-right: 0;
}

.options-effect #kit-info-cat .kj-price {
  display: none;
}

.options-effect .cat-options .option-block {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

.options-effect .cat-options .option-block .option-name {
  margin-right: 12px;
  color: #999ea5;
  font-size: 14px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  flex: 0 1 120px;
}

.options-effect .cat-options .option-block select {
  padding-left: 14px;
  line-height: 34px;
  height: 34px;
  border-radius: 0;
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.options-effect .cat-options .option-block:last-child {
  margin-bottom: 0;
}

/* main-form */
.main-form {
  position: relative;
  overflow-y: hidden;
  padding: 60px 0;
  background: url(../../../../../image/catalog/new/6-form.jpg) no-repeat center center;
  background-size: cover;
}

.main-form .main-form-success {
  position: absolute;
  z-index: 3;
  bottom: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #eeeeee;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 18px;
  transition: all .25s ease;
}

.main-form .main-form-success.active {
  bottom: 0;
}

.main-form h2 {
  margin: 0 0 30px;
  text-align: left;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 30px;
  text-transform: initial;
}

.main-form p {
  line-height: 24px;
  margin: 0 0 16px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.main-form .form {
  display: flex;
  flex-flow: row wrap;
}

.main-form .form input::placeholder {
  color: #c9c9c9;
}

.main-form .form input {
  width: calc(50% - 30px);
  margin: 0 30px 30px 0;
}

.main-form .form input[name="fname"],
.main-form .form input[name="ftelephone"] {
  padding: 13px 10px 14px 20px;
  border: none;
  background: #ffffff;
  font-size: 12px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
}

.main-form .form input[name="ftelephone"] {
  width: calc(50% - 46px);
  margin: 0 46px 30px 0;
}

/* article */
.news {
  padding: 40px 0;
}

.news .owl-wrapper-outer {
  box-shadow: none;
}

.news .oct-carousel-header {
  margin: 0 15px 40px;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 30px;
  text-align: left;
  text-transform: initial;
}

.news .oct-carousel-header:after {
  display: none;
}

.news .name {
  position: absolute;
  left: 30px;
  bottom: 0;
  width: 60%;
  padding: 16px 20px;
  background: #f7f6f2;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.news .name a:hover {
  color: #70a335;
}

.news .news-link {
  margin-top: 10px;
  margin-right: 80px;
  text-align: right;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.news .news-link a:hover {
  color: #70a335;
}

.news .news-link:hover {
  color: #70a335;
}

.news .news-link i {
  padding: 0 0 0 12px;
}

/* category */
#subcats .subcat-box:hover {
  transform: translateY(0);
}

#subcats .subcat-box a {
  font-size: 12px;
  font-weight: normal;
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
}

#subcats .subcat-box:hover a {
  text-decoration: none;
}

#subcats .subcat-box:hover img {
  opacity: 1;
}

/* category sidebar */
#sstore-3-level ul:first-child > li > a {
  padding-top: 18px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
}

#sstore-3-level > ul:not(:first-child) > li > a.category-name-a {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
}

#sstore-3-level > ul > li > a {
  padding: 14px;
}

#sstore-3-level > ul:not(:first-child) > li > a.category-name-a {
  position: relative;
}

#sstore-3-level > ul:not(:first-child) > li > a.category-name-a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: #6d6d6d;
}

#sstore-3-level > ul:last-child > li > a.category-name-a {
  padding-bottom: 22px;
}

#sstore-3-level > ul:last-child > li > a.category-name-a:after {
  content: '';
  position: absolute;
  bottom: 11px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: #6d6d6d;
}

#sstore-3-level ul > li.has-sub > a.toggle-a:after,
#sstore-3-level ul > li.has-sub > a.toggle-a:before {
  transition: all .05s ease;
}

#sstore-3-level ul > li.has-sub.open > a.toggle-a:before {
  transform: rotate(0);
}

#sstore-3-level ul > li.has-sub.open > a.toggle-a:after {
  transform: rotate(0) translateY(2px);
  visibility: hidden;
}

#sstore-3-level ul > li.has-sub > a.toggle-a:before {
  top: 19px;
}

#sstore-3-level ul > li.has-sub > a.toggle-a:after {
  top: 15px;
}

#sstore-3-level ul:first-child li.has-sub > a.toggle-a:before,
#sstore-3-level ul:first-child li.has-sub > a.toggle-a:after {
  background: #ffffff !important;
}

#sstore-3-level ul > li.has-sub > a.toggle-a:before,
#sstore-3-level ul > li.has-sub > a.toggle-a:after {
  background: #ffffff !important;
}

#sstore-3-level ul > li.has-sub.open > a.toggle-a:after,
#sstore-3-level ul > li.has-sub.open > a.toggle-a:before {
  background: #ffffff !important;
}

#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:after,
#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:before {
  background: #515356 !important;
}

#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:before {
  top: 26px;
}

#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:after {
  top: 22px;
}

#sstore-3-level ul ul li a {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

#sstore-3-level ul ul ul li a:hover {
  color: #515356;
}

#subcats {
  margin: 0 -10px;
}

#subcats .subcat-box {
  padding: 10px;
  margin: 0;
  border: none;
}

#subcats .subcat-box a {
  display: block;
  border: 1px solid #efefef;
}

#subcats .subcat-box a img {
  padding: 8px;
}

.row.sort-row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  background: transparent;
}

.row.sort-row:before, .row.sort-row:after {
  display: none;
}

.row.sort-row .appearance {
  display: none;
  margin: 0;
}

.row.sort-row .appearance .btn-group > .btn {
  outline: none;
  border: none;
  background: #eeeeee;
}

.row.sort-row .appearance .btn-group > .btn:hover, .row.sort-row .appearance .btn-group > .btn:focus, .row.sort-row .appearance .btn-group > .btn:active {
  outline: none;
  border: none;
  background: #eeeeee;
}

.row.sort-row .appearance .btn-group > .btn i {
  color: #e76b18;
}

.row.sort-row .right-sort-row {
  display: flex;
  flex-flow: row wrap;
  margin-right: 0;
}

.row.sort-row .select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 0;
}

.row.sort-row .select-wrapper:last-child {
  margin-right: 0;
}

.row.sort-row .select-wrapper:before {
  content: "\e91f";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-size: 8px;
  color: #515356;
}

.row.sort-row .select-wrapper label {
  width: auto;
  margin: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.row.sort-row .select-wrapper #input-sort,
.row.sort-row .select-wrapper #input-limit {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #515356;
  height: 24px;
  width: 100%;
  border: none;
  padding: 2px 20px 2px 20px;
  margin: 0;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

/* product cart */
#res-products .product-grid {
  min-height: 380px;
  margin: 0 0 18px;
}

.product-thumb {
  overflow-y: hidden;
  height: 498px;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.oct-discount-item {
  border-radius: 0;
}

.product-thumb:hover .cart .oct-button {
  background: #70a335 !important;
  color: #ffffff !important;
}

.product-thumb .product-thumb-caption {
  overflow-x: hidden;
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  bottom: -80px;
  z-index: 0;
  width: 100%;
  padding-top: 8px;
  padding-right: 0;
  background: #ffffff;
  transition: all .25s ease;
}

.product-thumb:hover .product-thumb-caption {
  bottom: 62px;
}

/* model */
.product-thumb .product-thumb-caption .model {
  height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: #909090;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

/* name */
.product-thumb .product-thumb-caption .name {
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
}

.product-thumb h4 a {
  margin: 0;
  font-size: 16px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.product-grid .product-thumb:hover .oct-product-desc {
  display: none;
}

/* price */
.product-thumb .price {
  display: flex;
  flex-direction: column;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.product-thumb .price .common-price {
  margin-top: 0;
}

.product-thumb .price .price-new {
  order: 2;
}

.product-thumb .price .price-old {
  order: 1;
}

.product-thumb .oct-product-stock {
  display: none;
}

/* rating */
.product-thumb .rating .fa-star-o {
  color: #eeeeee;
}

.product-thumb .rating .fa-star-o:before {
  content: "\f005";
}

.product-thumb .rating .fa-star {
  color: #dada00;
}

.product-thumb .rating {
  position: static;
  margin: 0 0 0 auto;
}

/* cart */
.product-thumb .cart {
  position: relative;
  bottom: 0;
  z-index: 2;
}

.product-grid .product-thumb .cart a {
  display: block;
}

.product-thumb .cart .oct-button {
  padding: 14px 20px 14px 20px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #eeeeee !important;
  color: #515356 !important;
  text-align: center;
}

.product-thumb .cart .oct-button:hover {
  background: #70a335 !important;
  color: #ffffff !important;
}

/* kjseries */
.product-thumb.kj-product-thumb-with-select .price {
  display: inline-block;
}

/* hover effect */
.product-grid .product-thumb .cat-box-effect {
  height: 80px;
}

/* additional hover image */
.product-grid .image .hover-image,
.product-list .image .hover-image {
  transition: all .6s ease-in-out;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}

.product-grid .image:hover .hover-image,
.product-list .image:hover .hover-image {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  transition: all .6s ease;
}

/* pagination */
.pagination-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 20px;
}

.pagination-row .text-left {
  margin: 0 0 20px;
}

.pagination {
  display: flex;
  border-radius: 0;
  border: none;
}

.pagination > li {
  display: block;
  height: 40px;
}

.pagination > li span,
.pagination > li a {
  display: block;
  height: 100%;
  margin-right: 4px;
  border: 1px solid #eeeeee;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li > a,
.pagination > li > span {
  display: block;
  height: 100%;
  margin-right: 4px;
  border: 1px solid #eeeeee;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover,
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background: #e76b18 !important;
  color: #ffffff !important;
  border: 1px solid #e76b18 !important;
}

.pagination > li > a,
.pagination > li > span {
  background: #ffffff !important;
  color: #515356 !important;
}

.pagination > li.active > a,
.pagination > li.active > span {
  background: #e76b18 !important;
  color: #ffffff !important;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-results {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.cat-desc-box p {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* filter */
.box-content.mfilter-content {
  border: none;
  border-radius: 0;
  padding: 6px 10px 10px;
  background: #eeeeee;
}

.mfilter-box .box-heading {
  background: #ffffff;
}

.mfilter-opts-container {
  background: #ffffff;
}

.mfilter-heading {
  position: relative;
  background: #eeeeee;
  border-top: none;
}

.mfilter-heading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: #ffffff;
}

.mfilter-content > ul > li:first-child .mfilter-heading:before {
  display: none;
}

.mfilter-heading-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mfilter-heading-text > span {
  padding-left: 22px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.mfilter-heading .mfilter-head-icon {
  position: relative;
  background: none;
  margin-top: 0;
  margin-right: 0;
}

.mfilter-heading .mfilter-head-icon:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 2px;
  background: #515356;
  transition: all .25s ease;
}

.mfilter-heading .mfilter-head-icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 2px;
  background: #515356;
}

.mfilter-price-slider {
  margin-left: -10px;
}

.mfilter-price-slider,
#mfilter-price-slider {
  background: transparent !important;
}

#mfilter-price-slider .ui-slider-range {
  height: 2px !important;
  background-color: #e76b18 !important;
  background-image: none !important;
}

#mfilter-price-slider .ui-slider-handle {
  top: -3px;
  width: 8px !important;
  height: 8px !important;
  margin: 0 !important;
  background: #e76b18 !important;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.mfilter-opts-container {
  border-top: none;
  background: #eeeeee;
}

.mfilter-tb-as-td {
  border-top: none;
}

.mfilter-counter.mfilter-close {
  background-position: 0 -11px !important;
}

.mfilter-tb-as-td.mfilter-col-input {
  position: relative;
}

.mfilter-tb-as-td.mfilter-col-input:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #eeeeee;
  background: #ffffff;
}

.mfilter-tb-as-td.mfilter-col-input input {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  visibility: hidden;
}

.mfilter-input-active .mfilter-col-input:before {
  border: 1px solid #e76b18;
  background: #e76b18;
}

.mfilter-tb-as-td {
  margin: 0 0 19px;
  padding: 0 0 9px 17px;
  font-size: 14px;
}

.mfilter-price-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#mfilter-opts-price-min,
#mfilter-opts-price-max {
  margin: 0 0 19px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 24px;
  display: inline-block;
  padding: 4px;
  font-size: 12px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  border: 1px solid #515356;
  border-radius: 0;
}

.oct-manufacturer-item:hover {
  transform: translateY(0);
}

.oct-manufacturer-item:hover a {
  color: #70a335;
  text-decoration: none;
}

#product-info-right {
  /* options */
  /* kit series */
  /* price and buttons */
  /* kit series addition */
}

#product-info-right .product-header {
  margin: 0;
  font-size: 24px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  text-transform: initial;
}

#product-info-right .product-info-status {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;
}

#product-info-right .product-info-status:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}

#product-info-right .product-info-status-and-model {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  font-size: 12px;
}

#product-info-right .product-info-status-and-model .product-info-li {
  margin: 0;
  background: none;
}

#product-info-right .product-info-status-and-model .product-info-li span {
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

#product-info-right .product-rating-box {
  text-align: right;
}

#product-info-right .product-rating-box a:hover {
  text-decoration: none;
}

#product-info-right .product-rating-box .rating {
  margin-right: 8px;
}

#product-info-right .product-rating-box .rating i {
  margin-right: 4px;
  color: #eeeeee;
}

#product-info-right .product-rating-box .rating i.fa-star {
  color: #ffd800;
}

#product-info-right .product-rating-box .rating i.fa-star + .fa-star-o {
  color: #ffd800;
}

#product-info-right .product-info-series-and-options {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 26px;
}

#product-info-right .product-info-series-and-options .options-header {
  margin: 0 0 10px;
  width: 100%;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
  text-transform: initial;
}

#product-info-right .product-info-series-and-options .product-info-li {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

#product-info-right .product-info-series-and-options .product-info-li label {
  margin: 0 0 2px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #999ea5;
}

#product-info-right .product-info-series-and-options .product-info-li select {
  padding-left: 14px;
  line-height: 34px;
  height: 34px;
  border-radius: 0;
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

#product-info-right .product-info-series-and-options .options-box {
  margin-top: 6px;
}

#product-info-right .product-info-series-and-options .options-box .radio {
  margin: 0;
}

#product-info-right .product-info-series-and-options .options-box .img-thumbnail {
  width: 26px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: 2px solid #eeeeee;
}

#product-info-right .product-info-series-and-options .options-box .selected-img:after {
  display: none;
}

#product-info-right .product-info-series-and-options .options-box .selected-img .img-thumbnail {
  border: 2px solid #70a335;
}

#product-info-right #kjseries {
  overflow-x: auto;
  margin-right: 38px;
}

#product-info-right #kjseries h4 {
  margin: 0 0 10px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #999ea5;
}

#product-info-right #kjseries .kjseries_type_images {
  display: flex;
}

#product-info-right #kjseries .kjseries_type_images .kj-item {
  padding: 0;
  margin: 0 5px 0 0;
  border: 2px solid #eeeeee;
  box-shadow: none;
}

#product-info-right #kjseries .kjseries_type_images .kj-item a {
  display: block;
}

#product-info-right #kjseries .kjseries_type_images .kj-item a img {
  width: 24px;
  height: 24px;
  margin: 0;
}

#product-info-right #kjseries .kjseries_type_images .kj-item:last-child {
  margin: 0;
}

#product-info-right #kjseries .kjseries_type_images .kj-item-selected {
  border: 2px solid #70a335;
}

#product-info-right .product-price-and-buttons {
  display: flex;
  flex-flow: row wrap;
  margin: 26px 0 32px;
}

#product-info-right .product-price-and-buttons .product-price {
  display: flex;
  flex-direction: column;
  min-width: 166px;
  margin-right: 16px;
}

#product-info-right .product-price-and-buttons .product-price h2 {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 22px;
}

#product-info-right .product-price-and-buttons .product-price h3 {
  color: #999ea5;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

#product-info-right .product-price-and-buttons .product-buttons-box {
  display: flex;
  width: auto;
}

#product-info-right .product-price-and-buttons .product-buttons-box .oct-button {
  min-width: 270px;
  width: auto;
  max-height: 48px;
  padding: 14px 15px 14px 15px;
  margin: 0 22px 0 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  border-radius: 0;
  text-transform: uppercase;
}

#product-info-right .product-price-and-buttons .product-buttons-box .oct-button:hover {
  background: #3a7403 !important;
}

#product-info-right .product-price-and-buttons .product-buttons-box .oct-preorder-button {
  background: #e76b18;
}

#product-info-right .product-price-and-buttons .product-buttons-box .oct-preorder-button:hover {
  background: #9d4f18 !important;
}

#product-info-right .product-price-and-buttons .product-buttons-box .button-one-click {
  min-width: 170px;
  width: auto;
  margin: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  background: #eeeeee;
  color: #515356 !important;
  text-transform: uppercase;
}

#product-info-right .product-price-and-buttons .product-buttons-box .button-one-click:hover {
  background: #515356 !important;
  color: #ffffff !important;
}

#product-info-right #kjseries-addition {
  overflow-x: auto;
}

#product-info-right #kjseries-addition h3 {
  margin: 0 0 10px;
  color: #515356;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: initial;
}

#product-info-right #kjseries-addition .table > tbody > tr {
  border-bottom: 1px solid #eeeeee;
}

#product-info-right #kjseries-addition .table > tbody > tr > td,
#product-info-right #kjseries-addition .table > tbody > tr > th,
#product-info-right #kjseries-addition .table > tfoot > tr > td,
#product-info-right #kjseries-addition .table > tfoot > tr > th,
#product-info-right #kjseries-addition .table > thead > tr > td,
#product-info-right #kjseries-addition .table > thead > tr > th {
  padding: 8px 8px 4px 8px;
  border: none;
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

#product-info-right #kjseries-addition .table > tbody > tr > td .btn-primary,
#product-info-right #kjseries-addition .table > tbody > tr > th .btn-primary,
#product-info-right #kjseries-addition .table > tfoot > tr > td .btn-primary,
#product-info-right #kjseries-addition .table > tfoot > tr > th .btn-primary,
#product-info-right #kjseries-addition .table > thead > tr > td .btn-primary,
#product-info-right #kjseries-addition .table > thead > tr > th .btn-primary {
  background: #eeeeee;
  color: #515356;
  width: 170px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 0;
  outline: none;
}

#product-info-right #kjseries-addition .table > tbody > tr > td .btn-primary:hover, #product-info-right #kjseries-addition .table > tbody > tr > td .btn-primary:active, #product-info-right #kjseries-addition .table > tbody > tr > td .btn-primary:focus,
#product-info-right #kjseries-addition .table > tbody > tr > th .btn-primary:hover,
#product-info-right #kjseries-addition .table > tbody > tr > th .btn-primary:active,
#product-info-right #kjseries-addition .table > tbody > tr > th .btn-primary:focus,
#product-info-right #kjseries-addition .table > tfoot > tr > td .btn-primary:hover,
#product-info-right #kjseries-addition .table > tfoot > tr > td .btn-primary:active,
#product-info-right #kjseries-addition .table > tfoot > tr > td .btn-primary:focus,
#product-info-right #kjseries-addition .table > tfoot > tr > th .btn-primary:hover,
#product-info-right #kjseries-addition .table > tfoot > tr > th .btn-primary:active,
#product-info-right #kjseries-addition .table > tfoot > tr > th .btn-primary:focus,
#product-info-right #kjseries-addition .table > thead > tr > td .btn-primary:hover,
#product-info-right #kjseries-addition .table > thead > tr > td .btn-primary:active,
#product-info-right #kjseries-addition .table > thead > tr > td .btn-primary:focus,
#product-info-right #kjseries-addition .table > thead > tr > th .btn-primary:hover,
#product-info-right #kjseries-addition .table > thead > tr > th .btn-primary:active,
#product-info-right #kjseries-addition .table > thead > tr > th .btn-primary:focus {
  background: #70a335;
  color: #ffffff;
}

.product-content-row {
  margin-bottom: 0;
}

.page_product .oct-carousel-row .oct-carousel-box {
  padding: 0 20px 20px;
}

/* image */
#image-additional .owl-item a {
  border: none;
}

#image-additional .owl-item img {
  padding: 4px;
}

/* popup */
.fancybox-controls {
  top: 50%;
  transform: translateY(-50%);
}

.fancybox-controls .fancybox-button--left {
  position: absolute;
  left: 0;
  border-radius: 0;
}

.fancybox-controls .fancybox-button--right {
  position: absolute;
  right: 0;
  border-radius: 0;
}

.fancybox-controls .fancybox-infobar__body {
  display: none;
}

/* bottom content */
.product-tabs-row .nav-tabs {
  margin: 0 0 24px;
}

.product-tabs-row .nav-tabs > li > a,
.product-tabs-row .nav-tabs > li.active > a {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  border-radius: 0;
}

.product-tabs-row .nav-tabs > li.active > a:before {
  display: none;
}

.product-tabs-row .nav-tabs > li:not(.active) > a {
  background: #eeeeee;
  color: #515356;
}

#main-product-you-save {
  background: #D51512;
  border-radius: 0;
}

/* attributes */
.oct-specification .head-td {
  background: transparent;
}

.oct-specification .row {
  position: relative;
}

.oct-specification .row:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: #eeeeee;
}

/* related */
.page_product .container .oct-carousel-header {
  position: relative;
  display: inline-block;
  line-height: 20px;
  padding: 10px 14px;
  margin: 0;
  background: #515356;
  color: #ffffff;
  font-family: 'Lato Heavy',Helvetica,Arial,sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}

.page_product .container .oct-carousel-header:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #515356;
}

.page_product .oct-carousel-box {
  padding-left: 0;
  padding-right: 0;
}

.page_product .oct-carousel-box-module-viewed {
  padding-left: 15px;
  padding-right: 15px;
}

.page_product .owl-carousel .owl-item .item {
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 512px;
  padding-right: 15px;
}

.page_product .owl-carousel .owl-item .image img {
  width: 100%;
}

.page_product .owl-carousel .owl-item .item:hover .oct-button {
  background: #70a335 !important;
  color: #ffffff !important;
}

.page_product .owl-carousel .owl-item .item .item-caption {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  bottom: -80px;
  z-index: 0;
  width: 100%;
  padding-top: 8px;
  background: #ffffff;
  transition: all .25s ease;
}

.page_product .owl-carousel .owl-item .item:hover .item-caption {
  bottom: 62px;
}

.page_product .owl-carousel .owl-item .item .item-caption .model {
  height: 24px;
  margin-bottom: 8px;
  text-align: left;
  color: #909090;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.page_product .owl-carousel .owl-item .item .item-caption .name {
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
}

.page_product .owl-carousel .owl-item .item .item-caption .name a {
  margin: 0;
  font-size: 16px;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.page_product .owl-carousel .owl-item .item .item-caption .name a:hover {
  text-decoration: none;
}

.page_product .owl-carousel .owl-item .price {
  display: flex;
  flex-direction: column;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.page_product .price-and-rating {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.page_product .owl-carousel .owl-item .rating {
  position: static;
  margin: 0 0 0 auto;
}

.page_product .owl-carousel .owl-item .cart {
  position: relative;
  bottom: 0;
  z-index: 2;
}

.page_product .owl-carousel .owl-item .cart .oct-button {
  padding: 14px 20px 14px 20px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #eeeeee !important;
  color: #515356 !important;
}

.page_product .owl-carousel .owl-item .cart .oct-button:hover {
  background: #70a335 !important;
}

.page_product .owl-pagination {
  display: none;
}

/* reviews */
#form-review textarea {
  height: auto;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #515356;
}

#form-review textarea::placeholder {
  color: #797c81;
}

#form-review textarea:hover, #form-review textarea:active, #form-review textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.oct-checkout-content {
  margin: 0;
  padding-left: 0;
  border: none;
  font-size: 16px;
}

/* h1 */
.page_oct_fastorder h1.cat-header {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 22px;
  text-transform: initial;
}

/* cart */
.oct-bottom-cart-box .table > thead > tr > td {
  background: #70a335;
  color: #ffffff;
}

.oct-bottom-cart-box .table .oct-bottom-cart-table-text {
  color: #515356;
}

#checkout-fastorder-page .table .oct-bottom-cart-table-text {
  color: #515356 !important;
}

.oct-bottom-cart-box .table > tbody > tr > td {
  border-top: 1px solid #eeeeee;
  border-bottom: none;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

.oct-bottom-cart-box .table > tbody > tr:last-child > td {
  border-bottom: 1px solid #eeeeee;
}

.oct-bottom-cart-box .table .oct-bottom-cart-table-text {
  font-size: 16px;
}

.oct-bottom-cart-bot-model {
  display: block;
  font-size: 12px;
  color: #eeeeee;
}

.oct-bottom-cart-box-attribute span {
  color: #999ea5;
  font-size: 14px;
}

.oct-bottom-cart-box-attribute img {
  width: 20px;
  height: 20px;
  border: 2px solid #999ea5;
}

.oct-bottom-cart-box-options span {
  color: #999ea5;
  font-size: 14px;
}

.oct-bottom-cart-box-quantity {
  display: flex;
  align-items: center;
  padding: 15px 0 !important;
}

.oct-bottom-cart-box-quantity > span {
  display: block;
  margin: 0 12px 0 0;
  color: #999ea5;
  font-size: 14px;
}

.oct-bottom-cart-box-quantity .oct-bottom-cart-box-quantity-block {
  position: relative;
}

.oct-bottom-cart-box-quantity .oct-bottom-cart-box-quantity-block .btn-plus {
  position: absolute;
  top: 0;
  right: 0;
}

.oct-bottom-cart-box-quantity .oct-bottom-cart-box-quantity-block .btn-plus button {
  border-radius: 0;
  border: none;
  outline: none;
  background: transparent;
  font-size: 8px;
}

.oct-bottom-cart-box-quantity .oct-bottom-cart-box-quantity-block .btn-minus {
  position: absolute;
  top: 16px;
  right: 0;
}

.oct-bottom-cart-box-quantity .oct-bottom-cart-box-quantity-block .btn-minus button {
  border-radius: 0;
  border: none;
  outline: none;
  background: transparent;
  color: #515356;
  font-size: 8px;
}

.oct-bottom-cart-box .table .form-control {
  padding-left: 0;
  padding-right: 12px;
  margin: 0;
  text-align: center;
  background: transparent;
  border: 1px solid #eeeeee;
}

.oct-bottom-cart-box td.delete-td button {
  color: #515356;
}

/* total */
.oct-bottom-cart-total-cart .total-text {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.fastorder-panel-group .oct-bottom-cart-total-cart .total-text span {
  color: #515356;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.oct-bottom-cart-total-cart .total-text:not(:first-child) {
  display: none;
}

/* coupon */
.oct-fastorder-next-choice .panel-group {
  margin: 0;
}

.panel-coupon,
.panel-coupon-modal {
  border: none;
  box-shadow: none;
}

.panel-coupon .panel-coupon-collapse-btn,
.panel-coupon-modal .panel-coupon-collapse-btn {
  padding: 0;
  margin: 0;
  color: #515356;
  outline: none;
  border: none;
  background: none;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-decoration: underline;
}

.panel-coupon .panel-coupon-collapse-btn:hover,
.panel-coupon-modal .panel-coupon-collapse-btn:hover {
  text-decoration: underline;
}

.panel-coupon p,
.panel-coupon-modal p {
  padding: 0 15px;
  margin: 0;
  font-size: 14px;
  color: #515356;
  text-decoration: underline;
}

.panel-coupon input.form-control,
.panel-coupon-modal input.form-control {
  height: 36px;
  padding: 7px 15px;
  font-size: 16px;
  margin: 1rem 0;
  border: 1px solid #f2f2f2;
  box-shadow: none;
}

.panel-coupon input.form-control:focus,
.panel-coupon-modal input.form-control:focus {
  border: 1px solid #f2f2f2;
  box-shadow: none;
}

.panel-coupon #button-coupon,
.panel-coupon #button-coupon-modal,
.panel-coupon-modal #button-coupon,
.panel-coupon-modal #button-coupon-modal {
  display: inline-block;
  padding: 11px 26px;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #70a335;
  background: #70a335;
  outline: none;
}

.panel-coupon #button-coupon:hover, .panel-coupon #button-coupon:focus, .panel-coupon #button-coupon:active,
.panel-coupon #button-coupon-modal:hover,
.panel-coupon #button-coupon-modal:focus,
.panel-coupon #button-coupon-modal:active,
.panel-coupon-modal #button-coupon:hover,
.panel-coupon-modal #button-coupon:focus,
.panel-coupon-modal #button-coupon:active,
.panel-coupon-modal #button-coupon-modal:hover,
.panel-coupon-modal #button-coupon-modal:focus,
.panel-coupon-modal #button-coupon-modal:active {
  background: #3a7403;
  color: #ffffff;
  border: 1px solid #3a7403;
  outline: none;
}

.panel-coupon #button-coupon-cancel,
.panel-coupon #button-coupon-cancel-modal,
.panel-coupon-modal #button-coupon-cancel,
.panel-coupon-modal #button-coupon-cancel-modal {
  display: inline-block;
  padding: 11px 15px;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  border: 1px solid #eeeeee;
  border-radius: 0;
  background: #ffffff;
  color: #515356;
  box-shadow: none;
}

.panel-coupon #button-coupon-cancel:hover, .panel-coupon #button-coupon-cancel:focus, .panel-coupon #button-coupon-cancel:active,
.panel-coupon #button-coupon-cancel-modal:hover,
.panel-coupon #button-coupon-cancel-modal:focus,
.panel-coupon #button-coupon-cancel-modal:active,
.panel-coupon-modal #button-coupon-cancel:hover,
.panel-coupon-modal #button-coupon-cancel:focus,
.panel-coupon-modal #button-coupon-cancel:active,
.panel-coupon-modal #button-coupon-cancel-modal:hover,
.panel-coupon-modal #button-coupon-cancel-modal:focus,
.panel-coupon-modal #button-coupon-cancel-modal:active {
  background: #515356;
  color: #ffffff;
  border: 1px solid #515356;
  outline: none;
}

.panel-coupon #button-coupon-modal,
.panel-coupon #button-coupon-cancel-modal,
.panel-coupon-modal #button-coupon-modal,
.panel-coupon-modal #button-coupon-cancel-modal {
  font-size: 14px;
}

.panel-coupon .panel-body,
.panel-coupon-modal .panel-body {
  padding: 0 0 10px;
  margin-top: 12px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.panel-coupon .panel-coupon-alert p,
.panel-coupon-modal .panel-coupon-alert p {
  text-decoration: none;
}

.panel-coupon .panel-body.error .input-group > input,
.panel-coupon-modal .panel-body.error .input-group > input {
  border-color: #FF0200;
}

.panel-coupon .panel-body.error p,
.panel-coupon-modal .panel-body.error p {
  color: #FF0200;
}

.panel-coupon .panel-body.valid .input-group > input,
.panel-coupon-modal .panel-body.valid .input-group > input {
  border-color: #70a335;
}

.panel-coupon .panel-body.valid .panel-coupon-alert p,
.panel-coupon-modal .panel-body.valid .panel-coupon-alert p {
  color: #70a335;
}

/* guest */
.oct-fastorder-panel-guest {
  margin: 0;
}

.oct-fastorder-header-guest {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #515356 !important;
}

.oct-checkout-content .login-checkout {
  padding: 0;
  margin: 0 0 20px;
}

.oct-checkout-content #show-login {
  margin: 0;
}

.oct-checkout-content #show-login .registered-text {
  margin: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_oct_fastorder .oct-checkout-content #show-login a {
  padding: 0;
  background: transparent;
  color: #e76b18 !important;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-decoration: underline;
  text-transform: lowercase;
}

.page_oct_fastorder .oct-checkout-content #show-login a:hover {
  color: #e76b18;
  background: transparent !important;
  text-decoration: none;
}

/* registered */
.payment-method-checking .radio {
  margin: 0 0 6px;
}

.payment-method-checking .radio label {
  line-height: 16px;
  padding: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.payment-method-checking .radio input[type=radio] {
  display: none;
}

.payment-method-checking .radio input[type=radio]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.payment-method-checking .radio span {
  position: relative;
  padding: 0 0 0 24px;
}

.payment-method-checking .radio span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  background-size: 0;
}

.payment-method-checking .radio:last-child {
  margin-top: 6px;
}

/* checkout info */
.register_block .panel {
  margin: 0 0 12px;
}

.panel-body-checkout {
  display: flex;
  /* payment */
}

.panel-body-checkout .row-checkout-info {
  flex-grow: 1;
  width: 66.6666%;
}

.panel-body-checkout .row-checkout-payment {
  flex-grow: 1;
  width: 33.3333%;
}

.panel-body-checkout .payment-method {
  padding: 0 0 0 30px;
}

.panel-body-checkout .payment-method .payment-method-inner {
  padding: 10px;
  background: #eeeeee;
}

.panel-body-checkout .payment-method .payment-method-inner p {
  margin: 6px 0 8px;
  font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-align: left;
}

.panel-body-checkout .payment-method .payment-method-inner .payment-method-caption {
  background: #ffffff;
  padding: 20px;
}

.panel-body-checkout .payment-method .payment-method-inner .radio {
  margin: 0 0 6px;
}

.panel-body-checkout .payment-method .payment-method-inner label {
  line-height: 16px;
  padding: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.panel-body-checkout .payment-method .payment-method-inner input[type=radio] {
  display: none;
}

.panel-body-checkout .payment-method .payment-method-inner input[type=radio]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.panel-body-checkout .payment-method .payment-method-inner span {
  position: relative;
  padding: 0 0 0 24px;
}

.panel-body-checkout .payment-method .payment-method-inner span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  background-size: 0;
}

#payment-existing:before,
#shipping-existing:before {
  right: 10px;
}

/* comment */
.comment-collapse-button {
  margin: 6px 0 6px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
  color: #e76b18;
  text-decoration: underline;
}

.comment-collapse-button:hover, .comment-collapse-button:active, .comment-collapse-button:focus {
  border: none;
  outline: none;
}

.form-group-comment {
  overflow: hidden;
  max-height: 0;
  margin: 0 0 12px;
  transition: max-height 0.2s ease-out;
}

.form-group-comment textarea {
  margin-top: 1px;
}

/* callback */
.oct-fastorder-callback label {
  line-height: 16px;
  padding: 0;
  margin: 0 0 8px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.oct-fastorder-callback label input[type=checkbox] ~ span {
  padding: 0 0 0 24px;
}

.oct-fastorder-callback label input[type=checkbox] ~ span:before {
  top: 3px;
}

/* register */
.oct-fastorder-register label {
  line-height: 16px;
  padding: 0;
  margin: 0 0 8px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.oct-fastorder-register label input[type=checkbox] ~ span {
  padding: 0 0 0 24px;
}

.oct-fastorder-register label input[type=checkbox] ~ span:before {
  top: 3px;
}

/* bottest cart total */
.oct-bottest-cart-total-cart {
  margin: 0 0 16px;
}

.oct-bottest-cart-total-cart .total-text {
  margin: 0 0 6px;
}

.oct-bottest-cart-total-cart .total-text:last-child {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
}

/* agree */
#agree-block {
  margin: 0 0 22px;
}

#agree-block .text-left {
  display: flex;
  align-items: center;
}

#agree-block .text-left a {
  margin-left: 5px;
  margin-right: 5px;
}

#agree-block .text-left input[type=checkbox] {
  display: none;
}

#agree-block .text-left input[type=checkbox]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

#agree-block .text-left span {
  position: relative;
  padding: 0 0 0 24px;
}

#agree-block .text-left span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  background-size: 0;
}

/* button checkout */
#button-go {
  display: inline-block;
  height: auto;
  padding: 14px 58px;
  background: #70a335;
  color: #ffffff;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 0;
  outline: none;
  text-transform: uppercase;
}

#button-go:hover {
  background: #e76b18;
  color: #ffffff;
}

/* after clicking checkout */
.oct-fastorder-payment > div:not(.buttons), .oct-fastorder-payment h2, .oct-fastorder-payment p {
  display: none !important;
}

.oct-fastorder-payment .buttons .pull-right button,
.oct-fastorder-payment .buttons .pull-right input:not(#button-go) {
  display: none !important;
}

/* success */
.page_success .account-content {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.oct-cart-item > div.item-name a {
  margin: 0 0 6px;
  font-size: 16px;
  color: #515356 !important;
}

.oct-cart-item > div.item-name small {
  font-size: 12px;
}

.oct-cart-item > div.delete-item button {
  color: #515356;
}

.popup-total-cart .popup-button {
  display: inline-block;
  height: auto;
  padding: 12px 36px;
  background: #70a335;
  color: #ffffff;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  outline: none;
}

.popup-total-cart .popup-button:hover {
  background: #3a7403;
  color: #ffffff;
}

.popup-buttons-box .popup-button + .popup-button {
  padding: 14px 36px;
  width: auto;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #515356 !important;
  color: #eeeeee !important;
  text-align: center;
}

.popup-buttons-box .popup-button + .popup-button:hover {
  background: #35373a !important;
  color: #ffffff !important;
}

.popup-buttons-box .popup-button {
  display: inline-block;
  height: auto;
  padding: 12px 36px;
  background: #70a335;
  color: #ffffff;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  outline: none;
}

.popup-buttons-box .popup-button:hover {
  background: #3a7403;
  color: #ffffff;
}

/* register */
.account-register i:not(.fa-calendar) {
  display: none;
}

.account-content h2 {
  margin: 0 0 20px;
  color: #515356;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 22px;
  text-align: left;
  text-transform: initial;
}

.account-content p {
  margin: 0 0 20px;
  font-size: 16px;
}

.account-content p a {
  color: #515356;
  text-decoration: underline;
}

.account-content form legend {
  padding-top: 0;
  margin: 0 0 12px;
  color: #515356;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-align: left;
  text-transform: initial;
}

.account-content form > div {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 18px;
}

.account-content form > div .form-group {
  display: flex;
  flex-direction: column;
  width: calc(25% - 30px);
  margin: 0 30px 12px 0;
}

.account-content form > div .form-group .control-label {
  margin: 0 0 10px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.account-content form > div .form-group.required .control-label:before {
  display: none;
}

.account-content form > div .form-group.required .control-label:after {
  content: '* ';
  margin-left: 2px;
  color: #F00;
  font-weight: bold;
}

.account-content form .select-wrapper {
  position: relative;
}

.account-content form .select-wrapper:before {
  content: "\e91f";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 8px;
  color: #515356;
}

.account-content form .select-wrapper select.form-control {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #515356;
  height: auto;
  width: 100%;
  border: none;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.account-content form .select-wrapper select {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #515356;
  height: auto;
  width: 100%;
  border: none;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.account-content form input[type=text],
.account-content form input[type=email],
.account-content form input[type=tel],
.account-content form input[type=password] {
  height: 44px;
  background: #eeeeee;
  border: none;
  border-radius: 0;
  outline: none;
  color: #515356;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  box-shadow: none;
}

.account-content form input[type=text]::placeholder,
.account-content form input[type=email]::placeholder,
.account-content form input[type=tel]::placeholder,
.account-content form input[type=password]::placeholder {
  color: #797c81;
}

.account-content form input[type=text]:hover, .account-content form input[type=text]:active, .account-content form input[type=text]:focus,
.account-content form input[type=email]:hover,
.account-content form input[type=email]:active,
.account-content form input[type=email]:focus,
.account-content form input[type=tel]:hover,
.account-content form input[type=tel]:active,
.account-content form input[type=tel]:focus,
.account-content form input[type=password]:hover,
.account-content form input[type=password]:active,
.account-content form input[type=password]:focus {
  outline: none;
  box-shadow: none;
}

.account-content form input[type=text] input[type=checkbox],
.account-content form input[type=email] input[type=checkbox],
.account-content form input[type=tel] input[type=checkbox],
.account-content form input[type=password] input[type=checkbox] {
  display: none;
}

.account-content form input[type=text] input[type=checkbox]:checked ~ span:before,
.account-content form input[type=email] input[type=checkbox]:checked ~ span:before,
.account-content form input[type=tel] input[type=checkbox]:checked ~ span:before,
.account-content form input[type=password] input[type=checkbox]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.account-content form input[type=text] input[type=checkbox] ~ span,
.account-content form input[type=email] input[type=checkbox] ~ span,
.account-content form input[type=tel] input[type=checkbox] ~ span,
.account-content form input[type=password] input[type=checkbox] ~ span {
  position: relative;
  padding: 0 0 0 20px;
}

.account-content form input[type=text] input[type=checkbox] ~ span:before,
.account-content form input[type=email] input[type=checkbox] ~ span:before,
.account-content form input[type=tel] input[type=checkbox] ~ span:before,
.account-content form input[type=password] input[type=checkbox] ~ span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  background-size: 0;
}

.account-content form .subscription .form-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  margin: 0;
}

.account-content form .subscription .form-group label.control-label {
  margin: 2px 20px 0 0;
  line-height: 16px;
  padding: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.account-content form .subscription .form-group label.control-label:before, .account-content form .subscription .form-group label.control-label:after {
  display: none;
}

.account-content form .subscription .form-group label {
  line-height: 16px;
  padding: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.account-content form .subscription .form-group input[type=radio] {
  display: none;
}

.account-content form .subscription .form-group input[type=radio]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.account-content form .subscription .form-group span {
  position: relative;
  padding: 0 0 0 24px;
}

.account-content form .subscription .form-group span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
}

.account-content form .buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.account-content form .buttons div {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 30px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.account-content form .buttons div:last-child {
  margin: 0;
}

.account-content form .buttons div label {
  line-height: 16px;
  padding: 0;
  margin: 0 0 6px;
}

.account-content form .buttons div label input[type=checkbox] {
  display: none;
}

.account-content form .buttons div label input[type=checkbox]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.account-content form .buttons div label span {
  position: relative;
  padding: 0 0 0 20px;
}

.account-content form .buttons div label span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
}

.account-content form .buttons div a {
  text-decoration: underline;
}

.account-content form .buttons div a b {
  margin: 0 6px;
  font-weight: normal;
}

.account-content .buttons div .button-back {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* account */
ul.account-ul li a {
  color: #515356;
}

ul.account-ul li i {
  color: #515356;
}

ul.account-ul li:hover a {
  background: transparent;
}

ul.account-ul li a:hover {
  background: transparent;
  color: #e76b18 !important;
}

ul.account-ul li a:hover i {
  color: #e76b18;
}

/* edit */
.page_edit .control-label {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* password */
.page_password .control-label {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* address edit */
.page_address .account-content form .select-wrapper:before {
  right: 24px;
}

.page_address .form-horizontal .control-label {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* subscription */
.page_newsletter label {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px !important;
}

/* history payments */
.page_transaction .table-div {
  border: none !important;
}

.page_transaction .table > tbody > tr > td,
.page_transaction .table > tbody > tr > th,
.page_transaction .table > tfoot > tr > td,
.page_transaction .table > tfoot > tr > th,
.page_transaction .table > thead > tr > td,
.page_transaction .table > thead > tr > th {
  border: 1px solid #515356 !important;
}

.page_transaction .wishlist-tr {
  background: #ffffff !important;
  color: #515356 !important;
}

/* login */
.page_login .well p.text {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_login .form-group label {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_login .form-group input + a {
  margin-top: 12px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.contact-info-box h1.cat-header {
  margin: 0 0 20px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 22px;
  text-transform: initial;
}

.contact-info-text-block {
  display: flex;
  margin: 0 0 20px;
}

.contact-info-text-block .contact-info-telephone,
.contact-info-text-block .contact-info-contacts {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contact-info-text-block .contact-info-telephone a,
.contact-info-text-block .contact-info-contacts a {
  display: block;
  margin: 0 0 8px;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
}

.contact-info-text-block .contact-info-telephone span,
.contact-info-text-block .contact-info-contacts span {
  padding: 0 10px 0 0;
  color: #70a335;
  font-size: 16px;
}

.contact-info-text-block .contact-info-telephone {
  flex: 0 1 210px;
  margin-right: 30px;
}

.contact-info-text-block .contact-info-contacts span {
  font-size: 14px;
}

.contact-info-social-block .contact-info-messenger {
  display: flex;
  margin: 0 0 10px;
}

.contact-info-social-block .contact-info-messenger p {
  flex: 0 1 366px;
  margin-right: 30px;
}

.contact-info-social-block .contact-info-messenger ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contact-info-social-block .contact-info-messenger ul a:hover {
  color: #70a335;
}

.contact-info-social-block .contact-info-messenger ul span {
  margin-right: 10px;
}

.contact-info-social-block .contact-info-messenger ul span.icon-viber {
  display: block;
  margin-top: 2px;
  margin-right: 11px;
  font-size: 17px;
}

.contact-info-social-block .contact-info-social {
  display: flex;
  align-items: center;
  margin: 0 0 18px;
}

.contact-info-social-block .contact-info-social p {
  flex: 0 1 112px;
  margin-right: 16px;
  margin-bottom: 3px;
}

.contact-info-social-block .contact-info-social ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contact-info-social-block .contact-info-social ul a:hover {
  color: #70a335;
}

.contact-info-social-block .contact-info-social ul span {
  margin-right: 17px;
}

.contact-info-address p {
  margin: 0 0 24px;
}

.contact-info-address p i {
  padding: 0 10px 0 0;
  color: #70a335;
  font-size: 16px;
}

.contact-info-map .map-box {
  overflow: hidden;
  max-height: 208px;
}

.contact-form-box p {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 20px;
}

.contact-form-box form fieldset .form-group {
  margin-bottom: 12px;
}

.contact-form-box form fieldset .form-group:nth-child(4) {
  margin-bottom: 30px;
}

.contact-form-box form fieldset .form-group:nth-child(4) textarea {
  height: 154px;
  margin: 0;
}

.contact-form-box .contact-button-box {
  padding: 0 15px 0 0;
}

.page_contribution .cat-header {
  margin: 0 0 20px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 22px;
  text-transform: initial;
}

.contribution-text-block {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 40px;
}

.contribution-text-block > p {
  margin: 0 0 40px;
}

.contribution-text-left p,
.contribution-text-right p {
  margin: 0 0 18px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 22px;
}

.contribution-text-left ul,
.contribution-text-right ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contribution-text-left ul li,
.contribution-text-right ul li {
  position: relative;
  padding: 0 0 0 38px;
  margin: 0 0 18px;
}

.contribution-text-left ul li:before,
.contribution-text-right ul li:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: #e76b18;
  background-size: 65%;
}

.contribution-text-left {
  width: 50%;
  padding: 0 15px 0 0;
}

.contribution-text-right {
  width: 50%;
  padding: 0 15px;
}

.contribution-contacts p {
  margin-right: 16px;
  margin-bottom: 28px;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.contribution-contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contribution-contacts ul li {
  margin: 0 0 10px;
}

.contribution-contacts ul span {
  padding-right: 10px;
  font-size: 14px;
  color: #70a335;
}

.contribution-form-box .contribution-form-box-container .contribution-form-box-container-top {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 34px;
}

.contribution-form-box .contribution-form-box-container .contribution-form-box-container-top > .form-group {
  width: 50%;
  margin: 0 0 12px;
}

.contribution-form-box .contribution-form-box-container .contribution-form-box-container-top > .form-group:nth-child(odd) {
  width: calc(50% - 30px);
  margin-right: 30px;
}

.contribution-form-box .contribution-form-box-container .custom-btn-third.custom-btn-lg {
  padding: 14px 76px;
}

.badge-box i {
  color: #515356;
}

.news-row .product-thumb {
  height: auto;
}

#product-options-popup .product-info-li.list-li {
  background: #ffffff;
}

.white-popup .text-danger {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

#popup-product-preorder-wrapper {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

#popup-product-preorder-wrapper h3 {
  color: #515356;
}

#popup-product-preorder-wrapper #special-price,
#popup-product-preorder-wrapper #main-price {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  color: #515356;
}

.popup-promo-text {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

#oneclick-popup {
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
}

#oneclick-popup h3 {
  color: #515356;
}

#oneclick-popup #special-price.special-main-price,
#oneclick-popup #main-price.oneclick-main-price {
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  color: #515356;
}

.page_sreview_reviews .row.sort-row .select-wrapper:before {
  right: 6px;
}

.page_return .form-group label {
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_return .form-group .input-group-btn {
  padding: 0;
  margin: 0;
}

.page_return .form-group .input-group-btn .btn {
  height: auto;
  padding: 12px 14px 13px;
  background: #ffffff;
  border-radius: 0;
}

.page_return .return-description {
  margin: 0 0 24px;
}

.page_return .return-description p {
  margin: 0;
  font-family: 'Lato Black', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_return .return-description p:last-child {
  margin-top: 24px;
}

.checkout-success .account-content h2 {
  text-align: center;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

#oct-bluring-box {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: #ffffff;
}

#oct-bluring-box > .container {
  flex: 1 0 auto;
  max-width: 100%;
}

.content-row {
  box-shadow: none;
}

.breadcrumb {
  font-size: 12px;
}

.breadcrumb i.fa-home {
  color: #70a335;
}

.breadcrumb > li span {
  color: #515356;
}

/* hide wishlist and compare */
a.wishlist.oct-button.current-link,
a.compare.oct-button.current-link {
  display: none !important;
}

a.wishlist.oct-button,
a.compare.oct-button {
  display: none !important;
}

a.oct-button.button-wishlist,
a.oct-button.button-compare {
  display: none !important;
}

/* hide currency */
#currency {
  display: none;
}

.product-thumb .cart a i[class="fa fa-shopping-cart"],
.item .cart a i[class="fa fa-shopping-cart"] {
  display: none;
}

.owl-buttons {
  display: none;
}

/* rating */
.rating .fa-star-o {
  color: #eeeeee;
}

.rating .fa-star-o:before {
  content: "\f005";
}

.rating .fa-star {
  color: #dada00 !important;
}

/* custom */
.custom-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.container-p-0 {
  padding: 0;
}

.m-r-0 {
  margin-right: 0 !important;
}

.oct-button {
  display: inline-block;
  height: auto;
  padding: 12px 36px;
  background: #70a335;
  color: #ffffff;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  outline: none;
}

.oct-button:hover {
  background: #3a7403 !important;
  color: #ffffff !important;
}

.custom-btn {
  display: inline-block;
  height: auto;
  padding: 8px 20px;
  background: #e76b18;
  color: #ffffff;
  font-family: 'Lato Heavy', Helvetica, Arial, sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 0;
  outline: none;
}

.custom-btn:hover, .custom-btn:active, .custom-btn:focus {
  background: #9d4f18;
  color: #ffffff;
}

.custom-btn-main {
  display: inline-block;
  height: auto;
  padding: 10px 36px;
  background: #70a335;
  color: #ffffff;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  outline: none;
}

.custom-btn-main:hover, .custom-btn-main:active, .custom-btn-main:focus {
  background: #3a7403 !important;
  color: #ffffff;
}

.custom-btn-second {
  padding: 8px 26px;
  width: 100%;
  border-radius: 0;
  font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background: #515356 !important;
  color: #eeeeee !important;
  text-align: center;
}

.custom-btn-second:hover, .custom-btn-second:active, .custom-btn-second:focus {
  background: #35373a !important;
  color: #ffffff !important;
}

/* alerts */
.alert-danger,
.alert-success {
  border-radius: 0;
}

.alert-success {
  font-size: 14px;
}

/* carousel */
.oct-carousel-row .oct-carousel-box {
  box-shadow: none;
}

.oct-carousel-header + .owl-carousel {
  padding-top: 30px;
  border-top: 1px solid #515356;
}

/* inputs - controls */
.page_checkout .form-group > label:not(.inner-input),
.page_contact .form-group > label:not(.inner-input),
.page_contribution .form-group > label:not(.inner-input),
.popup-form-box .form-group > label:not(.inner-input) {
  display: none;
}

.page_checkout .form-group > input,
.page_contact .form-group > input,
.page_contribution .form-group > input,
.popup-form-box .form-group > input {
  height: 44px;
  border: none;
  background: #eeeeee !important;
  border-radius: 0 !important;
  outline: none;
  color: #515356;
}

.page_checkout .select-wrapper,
.page_contact .select-wrapper,
.page_contribution .select-wrapper,
.popup-form-box .select-wrapper {
  position: relative;
}

.page_checkout .select-wrapper:before,
.page_contact .select-wrapper:before,
.page_contribution .select-wrapper:before,
.popup-form-box .select-wrapper:before {
  content: "\e91f";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  font-size: 8px;
  color: #515356;
}

.page_checkout .select-wrapper select.form-control,
.page_contact .select-wrapper select.form-control,
.page_contribution .select-wrapper select.form-control,
.popup-form-box .select-wrapper select.form-control {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #515356;
  height: 44px;
  width: 100%;
  border: none;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_checkout .select-wrapper select,
.page_contact .select-wrapper select,
.page_contribution .select-wrapper select,
.popup-form-box .select-wrapper select {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #515356;
  height: 44px;
  width: 100%;
  border: none;
  padding: 12px 20px 12px 20px;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.page_checkout textarea,
.page_contact textarea,
.page_contribution textarea,
.popup-form-box textarea {
  height: auto;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  box-shadow: none;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #515356;
}

.page_checkout textarea::placeholder,
.page_contact textarea::placeholder,
.page_contribution textarea::placeholder,
.popup-form-box textarea::placeholder {
  color: #797c81;
}

.page_checkout textarea:hover, .page_checkout textarea:active, .page_checkout textarea:focus,
.page_contact textarea:hover,
.page_contact textarea:active,
.page_contact textarea:focus,
.page_contribution textarea:hover,
.page_contribution textarea:active,
.page_contribution textarea:focus,
.popup-form-box textarea:hover,
.popup-form-box textarea:active,
.popup-form-box textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.page_checkout input[type=text],
.page_checkout input[type=email],
.page_checkout input[type=tel],
.page_contact input[type=text],
.page_contact input[type=email],
.page_contact input[type=tel],
.page_contribution input[type=text],
.page_contribution input[type=email],
.page_contribution input[type=tel],
.popup-form-box input[type=text],
.popup-form-box input[type=email],
.popup-form-box input[type=tel] {
  height: 44px;
  background: #eeeeee;
  border: none;
  border-radius: 0;
  outline: none;
  color: #515356;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  box-shadow: none;
}

.page_checkout input[type=text]::placeholder,
.page_checkout input[type=email]::placeholder,
.page_checkout input[type=tel]::placeholder,
.page_contact input[type=text]::placeholder,
.page_contact input[type=email]::placeholder,
.page_contact input[type=tel]::placeholder,
.page_contribution input[type=text]::placeholder,
.page_contribution input[type=email]::placeholder,
.page_contribution input[type=tel]::placeholder,
.popup-form-box input[type=text]::placeholder,
.popup-form-box input[type=email]::placeholder,
.popup-form-box input[type=tel]::placeholder {
  color: #797c81;
}

.page_checkout input[type=text]:hover, .page_checkout input[type=text]:active, .page_checkout input[type=text]:focus,
.page_checkout input[type=email]:hover,
.page_checkout input[type=email]:active,
.page_checkout input[type=email]:focus,
.page_checkout input[type=tel]:hover,
.page_checkout input[type=tel]:active,
.page_checkout input[type=tel]:focus,
.page_contact input[type=text]:hover,
.page_contact input[type=text]:active,
.page_contact input[type=text]:focus,
.page_contact input[type=email]:hover,
.page_contact input[type=email]:active,
.page_contact input[type=email]:focus,
.page_contact input[type=tel]:hover,
.page_contact input[type=tel]:active,
.page_contact input[type=tel]:focus,
.page_contribution input[type=text]:hover,
.page_contribution input[type=text]:active,
.page_contribution input[type=text]:focus,
.page_contribution input[type=email]:hover,
.page_contribution input[type=email]:active,
.page_contribution input[type=email]:focus,
.page_contribution input[type=tel]:hover,
.page_contribution input[type=tel]:active,
.page_contribution input[type=tel]:focus,
.popup-form-box input[type=text]:hover,
.popup-form-box input[type=text]:active,
.popup-form-box input[type=text]:focus,
.popup-form-box input[type=email]:hover,
.popup-form-box input[type=email]:active,
.popup-form-box input[type=email]:focus,
.popup-form-box input[type=tel]:hover,
.popup-form-box input[type=tel]:active,
.popup-form-box input[type=tel]:focus {
  outline: none;
  box-shadow: none;
}

.page_checkout input[type=checkbox],
.page_contact input[type=checkbox],
.page_contribution input[type=checkbox],
.popup-form-box input[type=checkbox] {
  display: none;
}

.page_checkout input[type=checkbox]:checked ~ span:before,
.page_contact input[type=checkbox]:checked ~ span:before,
.page_contribution input[type=checkbox]:checked ~ span:before,
.popup-form-box input[type=checkbox]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.page_checkout input[type=checkbox] ~ span,
.page_contact input[type=checkbox] ~ span,
.page_contribution input[type=checkbox] ~ span,
.popup-form-box input[type=checkbox] ~ span {
  position: relative;
  padding: 0 0 0 20px;
}

.page_checkout input[type=checkbox] ~ span:before,
.page_contact input[type=checkbox] ~ span:before,
.page_contribution input[type=checkbox] ~ span:before,
.popup-form-box input[type=checkbox] ~ span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  background-size: 0;
}

input[type="text"].form-control,
select.form-control,
input[type="password"].form-control,
input[type="email"].form-control,
input[type="tel"].form-control,
#auth-popup .popup-form-box input {
  height: 44px;
  background: #eeeeee;
  border: none;
  border-radius: 0;
  outline: none;
  color: #515356;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  box-shadow: none;
}

input[type="text"].form-control::placeholder,
select.form-control::placeholder,
input[type="password"].form-control::placeholder,
input[type="email"].form-control::placeholder,
input[type="tel"].form-control::placeholder,
#auth-popup .popup-form-box input::placeholder {
  color: #797c81;
}

input[type="text"].form-control:hover, input[type="text"].form-control:active, input[type="text"].form-control:focus,
select.form-control:hover,
select.form-control:active,
select.form-control:focus,
input[type="password"].form-control:hover,
input[type="password"].form-control:active,
input[type="password"].form-control:focus,
input[type="email"].form-control:hover,
input[type="email"].form-control:active,
input[type="email"].form-control:focus,
input[type="tel"].form-control:hover,
input[type="tel"].form-control:active,
input[type="tel"].form-control:focus,
#auth-popup .popup-form-box input:hover,
#auth-popup .popup-form-box input:active,
#auth-popup .popup-form-box input:focus {
  outline: none;
  box-shadow: none;
}

.form-group.has-error input[type=text],
.form-group.has-error input[type=email],
.form-group.has-error input[type=tel],
.form-group.has-error input[type=password],
.form-group.has-error select.form-control,
.form-group.has-error select,
.select-wrapper.has-error input[type=text],
.select-wrapper.has-error input[type=email],
.select-wrapper.has-error input[type=tel],
.select-wrapper.has-error input[type=password],
.select-wrapper.has-error select.form-control,
.select-wrapper.has-error select {
  background: #ffc0bf !important;
}

.custom-radio label {
  line-height: 16px;
  padding: 0;
  font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.custom-radio label input[type=radio] {
  display: none;
}

.custom-radio label input[type=radio]:checked ~ span:before {
  background-color: #e76b18;
  border-color: #e76b18;
  background-size: 80%;
}

.custom-radio label span {
  position: relative;
  padding: 0 0 0 24px;
}

.custom-radio label span:before {
  content: "";
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transparent url(../image/checkbox.svg) no-repeat 50%;
  background-size: 0;
}

.form-control:focus {
  border-color: #70a335;
}

/* danger */
.text-danger {
  margin-top: 2px;
}

/* popup */
.white-popup {
  border-radius: 0;
}

/* popup - condition */
.modal-content {
  border-radius: 0;
}

/* callback */
#uptocall-mini {
  border: 10px solid #70a33521;
}

@media screen and (max-width: 1200px) {
  /* top panel */
  .ts-search-box {
    flex: 0 1 200px;
  }
  /* header */
  .ts-menu-box .ts-menu-list li a {
    padding: 0 8px;
    font-size: 14px;
  }
  .phones-top-box .phone-list {
    padding: 0;
  }
  .phones-top-box .phone-list:before {
    left: -28px;
  }
  .phones-top-box .phone-list li a {
    font-size: 14px;
  }
  /* category */
  .row.sort-row {
    justify-content: flex-start;
  }
  .row.sort-row .left-sort-row {
    margin: 0 0 20px;
  }
  /* contact */
  .contact-info-text-block .contact-info-telephone {
    flex: 0 1 310px;
  }
  /* account */
  .account-register form > div#address {
    align-items: flex-end;
  }
}

@media only screen and (max-width: 1024px) {
  /* category */
  .sort-row .input-group-addon {
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  /* category */
  .product-thumb .cart a.button-cart {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 992px) {
  /* top panel menu */
  #menu-mobile {
    overflow-y: scroll;
  }
  .menu-mobile-header {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 40px;
    background: #f0f0f0;
    color: #515356 !important;
    font-size: 14px;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  }
  .menu-mobile-header i {
    margin-right: 6px;
    font-size: 26px;
  }
  .menu-mobile-header span {
    font-size: 16px;
  }
  .menu-mobile-loggin {
    display: flex;
    justify-content: space-between;
    padding: 12px 35px;
    background: #515356;
  }
  .menu-mobile-loggin ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .menu-mobile-loggin ul li {
    display: flex;
    padding: 0;
    margin: 0;
  }
  .menu-mobile-loggin ul li button,
  .menu-mobile-loggin ul li a {
    display: block;
    padding: 0 5px;
    margin: 0;
    border: none;
    border-right: 1px solid #ffffff;
    outline: none;
    box-shadow: none;
    color: #ffffff;
    font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
    font-size: 15px;
  }
  .menu-mobile-loggin ul li:focus, .menu-mobile-loggin ul li:active, .menu-mobile-loggin ul li:hover {
    background: none;
    color: #e76b18;
  }
  .menu-mobile-loggin ul li:last-child button,
  .menu-mobile-loggin ul li:last-child a {
    border-right: none;
  }
  .menu-mobile-loggin ul li i,
  .menu-mobile-loggin ul li button.active {
    color: #e76b18;
  }
  #menu-mobile-box {
    padding-left: 0;
    padding-right: 0;
  }
  #menu-mobile-box #menu .navbar-nav {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  #menu-mobile-box #menu .nav > li {
    padding: 10px 3px;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
  }
  #menu-mobile-box #menu .nav > li > a {
    display: inline-block;
    padding: 4px;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: #515356 !important;
    text-align: center;
    vertical-align: top;
  }
  #menu-mobile-box #menu .nav > li > a:not(.parent-title-toggle) {
    border: 1px solid transparent;
  }
  #menu-mobile-box #menu .nav > li > a:not(.parent-title-toggle):hover, #menu-mobile-box #menu .nav > li > a:not(.parent-title-toggle):active, #menu-mobile-box #menu .nav > li > a:not(.parent-title-toggle):focus {
    border: 1px solid #70a335;
    color: #70a335 !important;
  }
  #menu-mobile-box .oct-mm-simplecat > a:not(.parent-title-toggle) {
    margin-left: 0;
  }
  #menu-mobile-box .oct-mm-simplecat-has-child > a:not(.parent-title-toggle) {
    margin-left: 60px;
  }
  #menu-mobile-box #menu .oct-mm-simplecat .second-level-li {
    padding: 10px 5px;
  }
  #menu-mobile-box #menu .oct-mm-simplecat .second-level-li > a {
    padding: 4px;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 15px;
    text-transform: initial;
    color: #515356 !important;
  }
  #menu-mobile-box #menu div.dropdown-menu {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #menu-mobile-box #menu .oct-mm-simplecat .second-level-li.has-child,
  #menu-mobile-box #menu .oct-mm-simplecat.oct-mm-simplecat-last {
    display: none;
  }
  #menu-mobile-box .megamenu-toggle-a:before {
    right: 28px;
    top: 11px;
    transform: rotate(45deg);
  }
  #menu-mobile-box .parent-title-toggle:after {
    right: 26px;
    top: 7px;
    transform: rotate(45deg);
  }
  #menu-mobile-box .megamenu-toggle-a,
  #menu-mobile-box .parent-title-toggle {
    position: relative !important;
    top: 0;
    left: -7px;
    height: 26px;
  }
  #menu-mobile-box .open > .megamenu-toggle-a:after,
  #menu-mobile-box .open > .megamenu-toggle-a:before,
  #menu-mobile-box .parent-title-toggle.opened:after,
  #menu-mobile-box .parent-title-toggle.opened:before {
    transform: rotate(-45deg);
  }
  #menu-mobile-box #menu .dropdown-inner a.see-all {
    display: none !important;
  }
  #menu-mobile-box #menu .open .dropdown-menu {
    text-align: center;
  }
  #menu-mobile-box .apppli-list {
    padding-left: 30px;
    padding-right: 30px;
    margin: 0;
    text-align: center;
    background: #f0f0f0;
  }
  #menu-mobile-box .apppli {
    list-style-type: none;
    padding: 10px 3px;
    margin: 0;
  }
  #menu-mobile-box .apppli a {
    padding: 4px;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 15px;
    border: 1px solid transparent;
  }
  #menu-mobile-box .apppli a:hover, #menu-mobile-box .apppli a:active, #menu-mobile-box .apppli a:focus {
    border: 1px solid #70a335;
    color: #70a335 !important;
  }
  #menu-mobile-box .footer-contacts-ul {
    list-style-type: none;
    padding: 10px 15px;
    margin: 0;
    background: #515356;
  }
  #menu-mobile-box .footer-contacts-ul li {
    padding: 10px 5px;
    margin: 0;
    color: #ffffff;
  }
  #menu-mobile-box .footer-contacts-ul li i {
    color: #ffffff;
    margin-right: 8px;
  }
  #menu-mobile-box .footer-contacts-ul li a {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
    color: #ffffff;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 15px;
  }
  #menu-mobile-box .footer-contacts-ul .oct-messengers {
    display: flex;
    align-items: center;
  }
  #menu-mobile-box .footer-contacts-ul .oct-messengers a {
    margin: 0 10px 0 0;
    font-size: 18px;
  }
  #menu-mobile-box .footer-contacts-ul .oct-messengers h5 {
    margin: 0 10px 0 0;
    font-size: 15px;
    color: #ffffff;
  }
  .dropdown.oct-mm-simplecat.oct-mm-simplecat-has-child.open {
    padding-bottom: 0 !important;
  }
  .dropdown-menu.megamenu-ischild-opened {
    padding-bottom: 0 !important;
  }
  .close-m-search {
    top: 5px;
    right: 4px;
  }
  .close-m-search a {
    color: #515356 !important;
    font-size: 36px;
  }
  /* top panel */
  #top .top-container {
    padding: 0;
  }
  #menu-mobile-toggle {
    display: flex;
    justify-content: center;
  }
  #menu-mobile-toggle span {
    margin-left: 8px;
    text-transform: uppercase;
  }
  .cart-mobile {
    padding: 0;
  }
  .cart-mobile #cart {
    position: relative;
    left: 15px;
    margin: 0 0 0 -15px;
    background: #70a335 !important;
  }
  .cart-mobile #cart a {
    padding: 0;
  }
  .cart-mobile #cart #cart-total i[class=icon-shopping-bag] {
    top: -19px;
    left: -6px;
  }
  /* header */
  header {
    padding: 79px 0 10px;
    margin: 0;
  }
  #m-wishlist,
  #m-compare {
    display: none;
  }
  .phones-top-box {
    display: block;
    width: 50%;
  }
  .phones-top-box .phone-list {
    align-items: flex-start;
    padding: 0 0 0 84px;
  }
  .phones-top-box .phone-list:before {
    left: 28px;
    font-size: 40px;
  }
  .phones-top-box .phone-list li a {
    font-size: 18px;
  }
  /* menu */
  #menu .nav > li > a {
    color: #70a335 !important;
  }
  #oct-slideshow0 {
    margin-top: 0 !important;
  }
  .oct-slideshow-plus {
    background-position: center center !important;
    background-size: cover !important;
  }
  .oct-slideshow-plus:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    opacity: .9;
    background: #eeeeee;
    height: 100%;
    width: 100%;
  }
  .oct-category-item-icon {
    display: block !important;
  }
  .main-banner {
    padding: 30px 0;
    background: none;
  }
  .main-advantages {
    padding: 30px 0;
    background: none;
  }
  .main-form {
    position: relative;
  }
  .main-form:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #eeeeee;
  }
  .main-form > .container {
    position: relative;
    z-index: 2;
  }
  /* category */
  .mfilter-free-container {
    top: 58px;
    background: #eeeeee;
  }
  .mfilter-free-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -1px 0 0 228px;
    border: 10px solid #e76b18;
    background: #e76b18;
  }
  .mfilter-free-button i {
    color: #ffffff;
  }
  .mfilter-free-container [id^="mfilter-free-container-"] {
    background: #eeeeee;
  }
  /* product */
  #product-info-right .product-info-status > div {
    margin: 0 0 12px;
  }
  #product-info-right .product-info-status-and-model {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  }
  #product-info-right .product-info-status-and-model .blue {
    display: none;
  }
  #product-info-right .product-info-status-and-model span {
    color: #515356;
    margin-top: 12px;
    margin-right: 4px;
  }
  #product-info-right .product-info-status-and-model .product-info-li {
    font-size: 14px;
    font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
    color: #515356;
  }
  #product-info-right .product-rating-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 14px;
    font-family: 'Lato Medium', Helvetica, Arial, sans-serif;
  }
  #product-info-right .product-rating-box a {
    text-decoration: underline;
    color: #515356;
  }
  #product-info-right #kjseries {
    margin-bottom: 14px;
  }
  .product-info-li {
    padding: 0;
  }
  #product-info-right .product-info-series-and-options {
    margin: 0 0 16px;
  }
  #product-info-right .product-price-and-buttons {
    margin: 16px 0 32px;
  }
  #product-info-right .product-price-and-buttons .product-buttons-box {
    flex-flow: row wrap;
  }
  #product-info-right .product-price-and-buttons .product-buttons-box .oct-button {
    margin-bottom: 14px;
  }
  #product-info-right .product-price-and-buttons .product-buttons-box .button-one-click {
    min-width: 270px;
  }
  /* checkout */
  .checkout_form {
    display: flex;
    flex-direction: column;
  }
  .panel-body-checkout .row-checkout-info,
  .panel-body-checkout .row-checkout-payment {
    width: 50%;
  }
  .panel-body-checkout .payment-method .payment-method-inner .payment-method-caption {
    padding: 6px;
  }
  /* contact */
  .contact-info-text-block {
    flex-flow: row wrap;
  }
  .contact-info-text-block .contact-info-telephone {
    flex: auto;
  }
  .contact-info-text-block .contact-info-telephone {
    margin: 0 0 14px;
  }
  /* footer */
  footer .third-row > div {
    margin: 0 0 16px;
  }
  footer .third-row > div:last-child {
    margin: 0;
  }
  footer .footer-bottom .oct-created-by {
    text-align: left;
  }
  .payment-box {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  /* menu */
  #menu-mobile-box #menu .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }
  /* category */
  .row.sort-row .left-sort-row {
    display: none;
  }
  .row.sort-row .right-sort-row {
    align-items: flex-start;
    width: 100%;
  }
  #input-limit,
  .input-limit-div {
    display: block !important;
    width: 100%;
    margin: 0 0 12px;
  }
  .row.sort-row .select-wrapper {
    width: auto;
    padding: 0;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .row.sort-row .select-wrapper:last-child {
    margin-bottom: 0;
  }
  .row.sort-row .select-wrapper:before {
    right: 20px;
  }
  /* checkout */
  .panel-body-checkout {
    flex-flow: row wrap;
  }
  .panel-body-checkout .row-checkout-info,
  .panel-body-checkout .row-checkout-payment {
    width: 100%;
  }
  .panel-body-checkout .payment-method {
    padding: 0;
  }
  .panel-body-checkout .payment-method .payment-method-inner .payment-method-caption {
    padding: 12px;
  }
  /* testimonials */
  .page_sreview_reviews .row.sort-row .select-wrapper:before {
    right: 7px;
  }
  /* footer */
  .payment-box {
    text-align: left;
  }
  /* account */
  .account-register form > div .form-group {
    width: calc(50% - 30px);
  }
  .account-register form > div .form-group:nth-of-type(2n) {
    width: 50%;
    margin: 0 0 12px 0;
  }
  .account-register form #account .form-group {
    width: 50%;
    margin: 0 0 12px 0;
  }
  .account-register form #account .form-group:nth-of-type(2n) {
    width: calc(50% - 30px);
    margin: 0 30px 12px 0;
  }
}

@media screen and (max-width: 580px) {
  /* header */
  .phones-top-box .phone-list {
    padding: 0 0 0 32px;
  }
  .phones-top-box .phone-list:before {
    top: 53%;
    left: -2px;
    font-size: 26px;
  }
  .phones-top-box .phone-list li a {
    font-size: 12px;
  }
  /* contribution */
  .contribution-text-block {
    margin: 0;
  }
  .contribution-text-left,
  .contribution-text-right {
    width: 100%;
    padding: 0;
    margin: 0 0 24px;
  }
  .contribution-contacts {
    margin: 0 0 44px;
  }
  .contribution-form-box .contribution-form-box-container .contribution-form-box-container-top {
    margin: 0;
  }
  .contribution-form-box .contribution-form-box-container .contribution-form-box-container-top .form-group {
    width: 100%;
    margin: 0 0 18px;
  }
  .contribution-form-box .contribution-form-box-container .contribution-form-box-container-top > .form-group:nth-child(odd) {
    width: 100%;
    margin: 0 0 18px;
  }
}

@media screen and (max-width: 480px) {
  /* base */
  .options-effect {
    display: none;
  }
  /* top panel */
  #menu .collapse {
    padding-left: 0;
    padding-right: 0;
  }
  .menu-mobile-header {
    padding-left: 18px;
  }
  .menu-mobile-header i {
    font-size: 18px;
  }
  .menu-mobile-header span {
    font-size: 12px;
  }
  #menu-mobile-box .oct-mm-simplecat-has-child > a:not(.parent-title-toggle) {
    margin-left: 52px;
  }
  .menu-mobile-loggin {
    padding: 14px 20px;
  }
  .menu-mobile-loggin ul li a {
    font-size: 14px;
  }
  #menu-mobile-box #menu .nav > li > a {
    padding: 4px;
  }
  .close-m-search {
    top: 4px;
    right: -7px;
  }
  .close-m-search a {
    font-size: 24px;
  }
  #menu-mobile-box #menu .nav > li > a {
    display: inline-block !important;
  }
  /* header */
  header {
    box-shadow: none;
  }
  /* common */
  .common-home .slogan {
    order: 1;
    line-height: 24px;
    margin: 10px 0;
    font-size: 16px;
  }
  .custom-top {
    display: flex;
    flex-direction: column;
  }
  #oct-slideshow0 {
    display: none !important;
  }
  .oct-slideshow-box {
    order: 1;
  }
  .oct-slideshow-plus {
    padding: 30px 0;
  }
  .oct-slideshow-plus .oct-slideshowplus-header {
    margin: 0 0 16px;
    font-size: 28px;
  }
  #oct-slideshow0 .owl-controls .container-r {
    display: none;
  }
  .cat-wall {
    order: 2;
    padding: 10px 0;
  }
  .cat-wall .row {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -7px;
  }
  .cat-wall .row > div {
    padding: 0 7px;
    margin: 0 0 7px;
  }
  .cat-wall .oct-category-item-box {
    display: flex !important;
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 100%;
    min-height: 90px;
    max-height: initial;
    padding: 2px 0;
    margin: 0;
  }
  .cat-wall .oct-category-item-box .oct-category-item-text .oct-category-item-header {
    padding: 0 4px;
    margin: 0;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: initial;
  }
  .cat-wall .oct-category-item-box .oct-category-item-text .oct-category-item-header:before {
    display: none;
  }
  .cat-wall .oct-category-item-box .oct-category-item-icon {
    padding-left: 0 !important;
  }
  .cat-wall .oct-category-item-box .oct-category-item-icon img {
    max-width: 90%;
    margin: 0 0 0 2px;
  }
  .cat-wall .oct-category-item-box:hover {
    transform: translateY(0);
  }
  .latest {
    order: 4;
  }
  .oct-latest {
    flex-flow: row wrap;
    padding: 0 15px 20px;
  }
  .oct-latest-header {
    border-bottom: none;
  }
  .oct-latest-header h2 {
    width: 100%;
    padding: 10px 10px 14px;
    background: transparent;
    color: #515356;
  }
  .oct-latest .owl-carousel {
    margin: 0;
  }
  .oct-latest .owl-wrapper-outer {
    border: none;
    border-radius: 0;
  }
  .oct-latest .owl-carousel .owl-item .item {
    height: auto;
    min-height: initial;
    padding-right: 0;
  }
  .oct-latest .owl-carousel .owl-item .image {
    margin: 0;
  }
  .oct-latest .owl-carousel .owl-item .image img {
    width: 100%;
  }
  .oct-latest .owl-carousel .owl-item .item .item-caption .model {
    display: none;
  }
  .oct-latest .owl-carousel .owl-item .item .item-caption {
    position: static;
    padding-top: 10px;
  }
  .oct-latest .owl-carousel .owl-item .item .item-caption .name {
    height: auto;
    margin: 0 0 2px;
    text-align: center;
  }
  .oct-latest .owl-carousel .owl-item .rating {
    margin: -2px 0 1px;
  }
  .oct-latest .owl-carousel .owl-item .rating .fa-star-o {
    font-size: 12px;
  }
  .oct-latest .owl-carousel .owl-item .price {
    height: auto;
    line-height: 30px;
    margin: 0 0 7px;
    font-size: 26px;
  }
  .oct-latest .owl-carousel .owl-item .price-old {
    line-height: 30px;
    color: #C9C9C9;
    text-decoration: line-through;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: center;
  }
  .oct-latest .owl-carousel .owl-item .item .options-effect {
    min-height: auto;
  }
  .oct-latest .owl-carousel .owl-item .cart .oct-button {
    padding: 20px 20px !important;
    background: #70a335 !important;
    color: #ffffff !important;
  }
  .oct-latest .owl-controls {
    margin-top: 10px;
    height: auto;
  }
  .oct-latest .owl-pagination {
    top: 4px;
  }
  .featured-em {
    order: 3;
  }
  .oct-featured-em {
    flex-flow: row wrap;
    padding: 0 15px 20px;
  }
  .oct-featured-em-header {
    border-bottom: none;
  }
  .oct-featured-em-header h2 {
    width: 100%;
    padding: 10px 10px 14px;
    background: transparent;
    color: #515356;
  }
  .oct-featured-em .owl-carousel {
    margin: 0;
  }
  .oct-featured-em .owl-wrapper-outer {
    border: none;
    border-radius: 0;
  }
  .oct-featured-em .owl-carousel .owl-item .item {
    height: auto;
    min-height: initial;
    padding-right: 0;
  }
  .oct-featured-em .owl-carousel .owl-item .image {
    margin: 0;
  }
  .oct-featured-em .owl-carousel .owl-item .image img {
    width: 100%;
  }
  .oct-featured-em .owl-carousel .owl-item .item .item-caption .model {
    display: none;
  }
  .oct-featured-em .owl-carousel .owl-item .item .item-caption {
    position: static;
    padding-top: 10px;
  }
  .oct-featured-em .owl-carousel .owl-item .item .item-caption .name {
    height: auto;
    margin: 0 0 2px;
    text-align: center;
  }
  .oct-featured-em .owl-carousel .owl-item .rating {
    margin: -2px 0 1px;
  }
  .oct-featured-em .owl-carousel .owl-item .rating .fa-star-o {
    font-size: 12px;
  }
  .oct-featured-em .owl-carousel .owl-item .price {
    height: auto;
    line-height: 30px;
    margin: 0 0 7px;
    font-size: 26px;
  }
  .oct-featured-em .owl-carousel .owl-item .price-old {
    line-height: 30px;
    color: #C9C9C9;
    text-decoration: line-through;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: center;
  }
  .oct-featured-em .owl-carousel .owl-item .item .options-effect {
    min-height: auto;
  }
  .oct-featured-em .owl-carousel .owl-item .cart .oct-button {
    padding: 20px 20px !important;
    background: #70a335 !important;
    color: #ffffff !important;
  }
  .oct-featured-em .owl-controls {
    margin-top: 10px;
    height: auto;
  }
  .oct-featured-em .owl-pagination {
    top: 4px;
  }
  .bestseller {
    order: 4;
  }
  .oct-bestseller {
    flex-flow: row wrap;
    padding: 0 15px 20px;
  }
  .oct-bestseller-header {
    border-bottom: none;
  }
  .oct-bestseller-header h2 {
    width: 100%;
    padding: 10px 10px 14px;
    background: transparent;
    color: #515356;
  }
  .oct-bestseller .owl-carousel {
    margin: 0;
  }
  .oct-bestseller .owl-wrapper-outer {
    border: none;
    border-radius: 0;
  }
  .oct-bestseller .owl-carousel .owl-item .item {
    height: auto;
    min-height: initial;
    padding-right: 0;
  }
  .oct-bestseller .owl-carousel .owl-item .image {
    margin: 0;
  }
  .oct-bestseller .owl-carousel .owl-item .image img {
    width: 100%;
  }
  .oct-bestseller .owl-carousel .owl-item .item .item-caption .model {
    display: none;
  }
  .oct-bestseller .owl-carousel .owl-item .item .item-caption {
    position: static;
    padding-top: 10px;
  }
  .oct-bestseller .owl-carousel .owl-item .item .item-caption .name {
    height: auto;
    margin: 0 0 2px;
    text-align: center;
  }
  .oct-bestseller .owl-carousel .owl-item .rating {
    margin: -2px 0 1px;
  }
  .oct-bestseller .owl-carousel .owl-item .rating .fa-star-o {
    font-size: 12px;
  }
  .oct-bestseller .owl-carousel .owl-item .price {
    height: auto;
    line-height: 30px;
    margin: 0 0 7px;
    font-size: 26px;
  }
  .oct-bestseller .owl-carousel .owl-item .price-old {
    line-height: 30px;
    color: #C9C9C9;
    text-decoration: line-through;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: center;
  }
  .oct-bestseller .owl-carousel .owl-item .item .options-effect {
    min-height: auto;
  }
  .oct-bestseller .owl-carousel .owl-item .cart .oct-button {
    padding: 20px 20px !important;
    background: #70a335 !important;
    color: #ffffff !important;
  }
  .oct-bestseller .owl-controls {
    margin-top: 10px;
    height: auto;
  }
  .oct-bestseller .owl-pagination {
    top: 4px;
  }
  .product-tab {
    order: 3;
  }
  .main-banner {
    order: 4;
    padding: 12px 0 0;
    background: none;
  }
  .main-banner .main-banner-context-title {
    font-size: 22px;
    text-align: left;
  }
  .main-banner .main-banner-context-subtitle {
    font-size: 18px;
    line-height: 26px;
    padding: 20px;
  }
  .main-banner .main-banner-context-subtitle {
    margin: 0 0 30px;
  }
  .main-banner ul {
    margin: 0 0 30px;
  }
  .main-form {
    order: 5;
    padding: 20px 0;
  }
  .main-form h2 {
    margin: 0 0 20px;
  }
  .main-form .form input[name=fname], .main-form .form input[name=ftelephone] {
    width: 100%;
    margin: 0 0 20px;
  }
  .main-form .custom-btn {
    padding: 15px 20px;
    width: 100% !important;
    text-align: center;
    font-size: 18px;
  }
  .main-form .form input {
    margin: 0;
  }
  .main-advantages {
    order: 6;
    padding: 20px 0 0;
  }
  .main-advantages .main-advantages-item .main-advantages-text p {
    font-size: 14px;
  }
  .main-advantages .main-advantages-item .main-advantages-text h4 {
    line-height: 24px;
  }
  .main-advantages > .container > .row > div:last-child .main-advantages-item {
    margin: 0;
  }
  .news {
    order: 7;
    padding: 20px 0 0;
  }
  .news .container-p-0 {
    padding: 0 15px;
  }
  .news .container-p-0 > .row {
    margin: 0 -15px;
  }
  .news .oct-carousel-header {
    margin: 0 15px;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
  }
  .news .oct-carousel-header + .owl-carousel {
    padding-top: 20px;
    border-top: none;
  }
  .news .owl-wrapper-outer {
    border: none;
    border-radius: 0;
  }
  .oct-carousel-box .news-item .image {
    padding-left: 0;
    padding-right: 0;
  }
  .news .name {
    left: 0;
    width: 65%;
    padding: 12px;
  }
  .news .news-link {
    margin-right: 0;
  }
  /* category */
  h1.cat-header {
    margin-bottom: 5px;
  }
  .row.sort-row {
    padding: 0;
    margin: 0 0 8px;
  }
  .row.sort-row .select-wrapper {
    flex: 170px 0 1;
    margin: 0 20px 0 0;
  }
  .row.sort-row .select-wrapper:last-child {
    flex: 70px 0 1;
    margin: 0 0 0 0;
  }
  .row.sort-row .select-wrapper #input-limit {
    padding: 2px 7px 2px 7px;
  }
  .row.sort-row .select-wrapper:before {
    right: 11px;
  }
  #res-products .product-grid {
    width: 100%;
  }
  .product-thumb {
    height: auto;
  }
  .product-thumb .product-thumb-caption {
    position: static;
    padding-top: 10px;
    margin: 0;
  }
  .product-grid .product-thumb h4 {
    height: auto;
  }
  .product-thumb .product-thumb-caption .name {
    margin: 0 0 2px;
    text-align: center;
  }
  .product-thumb .product-thumb-caption .model {
    display: none;
  }
  .price-and-rating {
    flex-flow: column-reverse wrap;
    align-items: center;
    margin: 0;
  }
  .product-thumb .rating {
    margin: -2px 0 1px;
  }
  .product-thumb .price {
    height: auto;
    line-height: 30px;
    margin: 0 0 7px;
    font-size: 26px;
  }
  .product-thumb .price .common-price {
    font-size: 26px;
    line-height: 26px;
    height: auto;
  }
  .product-thumb .price-old {
    line-height: 30px;
    font-family: 'Lato SemiBold', Helvetica, Arial, sans-serif;
  }
  .product-thumb .options-effect {
    min-height: auto;
    display: none;
  }
  .product-thumb .cart .oct-button {
    padding: 20px 20px !important;
    background: #70a335 !important;
    color: #ffffff !important;
  }
  .product-grid .product-thumb .cart a.out-of-stock-button {
    background: #e76b18 !important;
    color: #ffffff !important;
  }
  .product-grid .product-thumb .cart a.out-of-stock-button:hover {
    background: #9d4f18 !important;
    color: #ffffff !important;
  }
  /* filter */
  .mfilter-free-container {
    top: 248px;
  }
  /* product */
  .content-row .left-info .thumbnail {
    margin-bottom: 0;
  }
  #image-additional {
    margin-bottom: 0;
  }
  #product-info-right .product-info-status {
    margin: 0 0 2px;
  }
  #product-info-right .product-info-status > div {
    margin: 0 0 8px;
  }
  #product-info-right .product-info-status-and-model .product-info-li {
    font-size: 12px;
  }
  #product-info-right .product-rating-box {
    width: 40%;
    font-size: 12px;
  }
  #product-info-right .product-rating-box .rating {
    margin-right: 0;
  }
  #product-info-right .product-info-status-and-model {
    width: 60%;
    font-size: 12px;
  }
  #product-info-right .product-info-status-and-model span {
    margin-top: 0;
  }
  #product-info-right .product-header {
    margin: 0 0 10px;
    font-size: 24px;
    line-height: 28px;
  }
  .product-price h2 {
    line-height: 40px;
  }
  .product-price h3 {
    margin-top: 6px;
  }
  #product .price-col {
    margin-bottom: 2px;
  }
  #product-info-right .product-price-and-buttons {
    margin: 0 0 32px;
  }
  #product-info-right .product-price-and-buttons .product-buttons-box .oct-button,
  #product-info-right .product-price-and-buttons .product-buttons-box .button-one-click {
    min-width: initial;
    max-width: 290px;
    width: 100%;
    margin: 0 0 14px;
  }
  .product-tabs-row .nav-tabs {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
  }
  .product-tabs-row .nav-tabs > li {
    width: auto;
    margin: 0;
  }
  .product-tabs-row .nav-tabs > li.active > a, .product-tabs-row .nav-tabs > li > a {
    padding: 7px;
    color: #515356 !important;
    background: #ffffff !important;
    font-size: 12px;
  }
  .product-tabs-row .nav-tabs > li:not(.active) > a {
    color: #929291 !important;
    font-size: 12px;
    background: #ffffff;
  }
  .product-tabs-row .tab-content {
    padding: 4px 0 0;
    line-height: 16px;
    font-size: 12px;
    font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  }
  .product-tabs-row #tab-description p {
    line-height: 18px;
    font-size: 14px;
    font-family: 'Lato Regular', Helvetica, Arial, sans-serif;
  }
  .page_product .owl-carousel {
    margin: 0;
  }
  .page_product .oct-carousel-box {
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center;
  }
  .page_product .container .oct-carousel-header {
    color: #515356;
    background: none;
  }
  .page_product .container .oct-carousel-header:after {
    display: none;
  }
  .page_product .container .oct-carousel-header + .owl-carousel {
    padding-top: 0;
    border-top: none;
  }
  .page_product .owl-carousel .owl-item .item {
    height: auto;
    padding-right: 0;
  }
  .page_product .owl-carousel .owl-item .item .item-caption .model {
    display: none;
  }
  .page_product .owl-carousel .owl-item .item .item-caption .name {
    margin-bottom: 5px;
    text-align: center;
  }
  .page_product .owl-carousel .owl-item .item .item-caption .name a {
    display: inline-block;
    text-align: center;
  }
  .page_product .price-and-rating {
    flex-direction: column-reverse;
    margin: 0 0 8px;
  }
  .page_product .owl-carousel .owl-item .rating {
    margin: 0 0 5px;
  }
  .page_product .owl-carousel .owl-item .price {
    margin-bottom: 5px;
  }
  .page_product .owl-carousel .owl-item .cart .oct-button {
    padding: 20px 8px !important;
    background: #70a335 !important;
    color: #ffffff !important;
  }
  .page_product .content-row + .oct-carousel-row .oct-carousel-box {
    padding-top: 0;
    padding-bottom: 0;
  }
  .page_product .owl-controls {
    margin-top: 0;
  }
  .page_product .owl-pagination {
    position: static;
    display: block;
    margin-top: 10px;
  }
  /* popup cart */
  .oct-cart-item > div.item-quantity {
    margin-left: 154px;
  }
  /* popup options */
  #product-options-popup {
    padding: 30px 30px 35px;
  }
  .popup-buttons-box {
    display: flex;
    flex-direction: column;
  }
  .popup-buttons-box .popup-button {
    margin: 0 0 12px;
  }
  .popup-buttons-box .popup-button:last-child {
    font-size: 13px;
    margin: 0;
  }
  /* checkout */
  .oct-bottom-cart-img-thumbnail {
    width: 48px;
    max-width: initial;
  }
  .oct-bottom-cart-box-quantity {
    padding: 25px 0 !important;
  }
  .oct-bottom-cart-box td.delete-td button {
    font-size: 20px;
  }
  .oct-bottom-cart-box .table .form-control {
    width: auto;
  }
  .oct-bottom-cart-total-cart .total-text {
    font-size: 14px;
  }
  .fastorder-panel-group .oct-bottom-cart-total-cart .total-text span {
    font-size: 14px;
  }
  .checkout_form {
    padding: 0;
  }
  .checkout_form > .col-sm-6,
  .checkout_form > .col-sm-12,
  .checkout_form > .col-md-12 {
    padding: 0;
  }
  .comment-collapse-button {
    padding: 0;
  }
  .panel-body-checkout .payment-method .payment-method-inner label {
    line-height: 18px;
    font-size: 14px;
  }
  .oct-fastorder-callback label,
  .oct-fastorder-register label {
    font-size: 14px;
  }
  .oct-bottest-cart-total-cart {
    font-size: 14px;
  }
  #agree-block .text-left {
    flex-flow: row wrap;
    font-size: 14px;
  }
  /* contact */
  .contact-info-text-block {
    flex-direction: column;
  }
  /* account */
  .account-register form > div .form-group {
    width: 100%;
    margin: 0 0 12px 0;
  }
  .account-register form > div .form-group:nth-of-type(2n) {
    width: 100%;
    margin: 0 0 12px 0;
  }
  .account-register form #account .form-group {
    width: 100%;
    margin: 0 0 12px 0;
  }
  .account-register form #account .form-group:nth-of-type(2n) {
    width: 100%;
    margin: 0 0 12px 0;
  }
  .account-register form .buttons div {
    flex-flow: row wrap;
  }
  .account-register form .buttons div:first-child {
    font-size: 14px;
  }
  /* footer */
  footer {
    margin-top: 12px;
  }
  footer .third-row {
    padding: 10px 0 20px;
  }
  footer .third-row .f-acc-toggle:before {
    right: 15px;
  }
  footer .third-row .f-acc-toggle:after {
    right: 13px;
  }
  footer .third-row .f-acc-toggle:before,
  footer .third-row .f-acc-toggle:after {
    background: #ffffff;
    transform: rotate(45deg);
  }
  footer .third-row .open .f-acc-toggle:before,
  footer .third-row .open .f-acc-toggle:after {
    transform: rotate(-45deg);
  }
  /* base */
  .content-row {
    margin-top: 0;
  }
  .breadcrumb-box {
    padding: 0;
    margin: 5px 0 0 0;
  }
  .breadcrumb {
    padding: 0 10px 2px 0;
    font-size: 10px;
  }
  .breadcrumb > li {
    display: inline-block !important;
  }
}

@media screen and (max-width: 420px) {
  /* top panel */
  .close-m-search {
    top: 6px;
    right: -11px;
  }
}

@media screen and (max-width: 376px) {
  /* top panel */
  .menu-mobile-header {
    height: auto;
  }
  #menu-mobile-toggle span {
    font-size: 12px;
  }
  .close-m-search {
    top: 0;
    right: -14px;
  }
  .cart-mobile #cart #cart-total i[class=icon-shopping-bag] {
    top: -11px;
  }
  /* header */
  header {
    padding: 50px 0 10px;
  }
  .phones-top-box .phone-list {
    padding: 0 0 0 28px;
  }
}

@media screen and (max-width: 360px) {
  /* top panel */
  .phones-top-box .phone-list:before {
    left: -7px;
  }
  .phones-top-box .phone-list {
    padding: 0 0 0 20px;
  }
  /* filter */
  .mfilter-free-container {
    top: 198px;
  }
  /* account */
  .account-register .custom-btn-md {
    padding: 12px 36px;
  }
}

/* mobile first */
@media screen and (min-width: 768px) {
  /* base */
  .custom-container {
    width: 100%;
  }
  .col-sm-5-and-half {
    width: 45.833333337%;
    padding: 0 15px;
  }
}

@media screen and (min-width: 992px) {
  /* base */
  .custom-container {
    width: 100%;
  }
  .col-md-4-and-half {
    width: 37.5%;
    padding: 0 15px;
  }
  .col-md-5-and-half {
    width: 45.833333337%;
    padding: 0 15px;
  }
  .custom-btn.custom-btn-md {
    padding: 14px 44px;
  }
  .custom-btn-main.custom-btn-md {
    padding: 14px 44px;
  }
  .custom-btn-second.custom-btn-md {
    padding: 14px 44px;
  }
}

@media screen and (min-width: 993px) {
  /* menu */
  #oct-menu-box {
    height: auto;
  }
}

@media screen and (min-width: 1024px) {
  /* category */
  .product-grid .product-thumb:hover {
    box-shadow: none;
    transform: translateY(0);
    position: static;
  }
  .product-grid .product-thumb .cart {
    height: auto;
  }
  .product-grid .product-thumb:hover h4 a {
    text-decoration: none;
    color: #515356 !important;
  }
  /* hover effect */
  .product-grid .product-thumb:hover .cat-box-effect-inner {
    transform: unset !important;
  }
  .product-grid .product-thumb:hover .oct-product-stock {
    display: none;
  }
  .product-grid .product-thumb:hover img {
    opacity: 1;
  }
}

@media screen and (min-width: 1200px) {
  /* header */
  .dropdown.phones-dropdown {
    margin-left: 15px;
  }
  /* base */
  .container {
    width: 1200px;
  }
  .custom-container {
    width: 100%;
  }
  .custom-btn.custom-btn-lg {
    padding: 14px 58px;
    font-size: 16px;
  }
  .custom-btn-main.custom-btn-lg {
    padding: 14px 58px;
  }
  .custom-btn-second.custom-btn-lg {
    padding: 14px 58px;
  }
  /* footer */
  .subscribe-content .custom-btn.custom-btn-lg {
    padding: 11px 58px;
  }
}
